import React from 'react';
import { Link } from 'react-router-dom';

const NoMatch = () => {
    return (
        <div
            className="d-flex flex-column justify-content-center align-items-center pb-5"
        >
            <h1 className='pb-4'>
                <span style={{ fontFamily: 'Montserrat' }}>404</span> غير موجود
            </h1>
            <Link
                className="btn btn-primary"
                to="/#"
                style={{
                    padding: '1rem 2rem',
                    fontSize: '1.2rem',
                    borderRadius: '2rem',
                }}
            >
                 الرجوع للصفحة الرئيسية
            </Link>
        </div>
    );
};

export default NoMatch;
