import React, { useEffect } from 'react';
import { Box, Link } from '@mui/material';

function FinancialTemplates() {

    useEffect(() => {
        // Disable cache for this page
        window.history.pushState(null, null, window.location.href);
        window.addEventListener('popstate', () => {
            window.history.pushState(null, null, window.location.href);
        });
    }, []);
    
    const links1 = [
        {
            label: 'محضر رقم (9) الإدارة المالية (وورد)',
            url: '/assets/files/managerial/2/محضر رقم 9 الادارة المالية.docx',
        },
        {
            label: 'محضر اتصال بعميل نموذج رقم (م/1) (وورد)',
            url: '/assets/files/managerial/2/محضر اتصال المالية نموذج رقم م 1.docx',
        },
        {
            label: 'حصر اتعاب العملاء نموذج رقم (م/2) (وورد)',
            url: '/assets/files/managerial/2/حصر اتعاب العملاء نموذج رقم م 2.docx',
        },
        {
            label: 'فهرس إجراءات التحصيل نموذج رقم (م/3) (وورد)',
            url: '/assets/files/managerial/2/فهرس إجراءات التحصيل نموذج رقم م 3.docx',
        },
        {
            label: 'مطالبة مالية بالسداد نموذج رقم (م/4) (وورد)',
            url: '/assets/files/managerial/2/مطالبة مالية بالسداد نموذج رقم م 4.docx',
        },
        {
            label: 'سند لأمر نموذج رقم (م/5) (وورد)',
            url: '/assets/files/managerial/2/محضر سند لامر نموذج رقم م 5.docx',
        },
        {
            label: 'عهدة مالية نموذج رقم (م/6) (وورد)',
            url: '/assets/files/managerial/2/محضر عهدة مالية نموذج رقم م 6.docx',
        },
        {
            label: 'عهدة عينية نموذج رقم (م/7) (وورد)',
            url: '/assets/files/managerial/2/محضر عهدة عينية نموذج رقم م 7.docx',
        },
        {
            label: 'تسجيل أصل للمنشأة نموذج رقم (م/8) (وورد)',
            url: '/assets/files/managerial/2/نموذج تسجيل أصل للمنشأة نموذج رقم م 8 فهرس ممتلكات للمنشأة.docx',
        },
        {
            label: 'أمر صرف نموذج رقم (م/9) (وورد)',
            url: '/assets/files/managerial/2/محضر أمر صرف نموذج رقم م 9.docx',
        },
        {
            label: 'اتلاف أصل للمنشأة نموذج رقم (م/10) (وورد)',
            url: '/assets/files/managerial/2/اتلاف أصل للمنشأة نموذج رقم م 10.docx',
        },
        {
            label: 'بيع أصل للمنشأة نموذج رقم (م/11) (وورد)',
            url: '/assets/files/managerial/2/محضر بيع أصل للمنشأة نموذج رقم م 11.docx',
        },
        {
            label: 'سند قبض حال اشكال منصة دفتره نموذج رقم (م/12) (وورد)',
            url: '/assets/files/managerial/2/سند قبض حال اشكال منصة دفتره نموذج رقم م 12.docx',
        },
        {
            label: 'سند صرف حال اشكال منصة دفتره نموذج رقم (م/13) (وورد)',
            url: '/assets/files/managerial/2/محضر سند صرف حال اشكال منصة دفتره نموذج رقم م 13.docx',
        },
        {
            label: 'خطة التحصيل نموذج رقم (م/14) (بي دي اف)',
            // url: '/assets/files/managerial/2/',
            url: '#',
        },
        {
            label: 'نصوص رسائل التحصيل للعلاء نموذج رقم (م/15) (وورد)',
            // url: '/assets/files/managerial/2/',
            url: '#',
        },
    ];
    return (
        <div className="px-3 pb-5">
            <h1 className="text-center mb-3">النماذج المالية</h1>
            <h3 className="text-center my-4">
                {links1.length} الإدارة المالية
            </h3>
            <Box
                dir="ltr"
                display="flex"
                flexDirection="column"
                alignItems="center"
                gap={2}
                mt={4}
            >
                {links1.map((link, index) => (
                    <Box
                        dir="ltr"
                        key={index}
                        component="div"
                        sx={{
                            border: '1px solid #ccc',
                            borderRadius: '8px',
                            padding: '10px 20px',
                            width: '100%',
                            maxWidth: '450px',
                            backgroundColor: '#f5f5f5',
                            '&:hover': {
                                backgroundColor: '#e0e0e0',
                                boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
                            },
                            transition: 'all 0.3s ease',
                        }}
                    >
                        <Link
                            href={link.url}
                            underline="none"
                            color="primary"
                            target="_blank"
                        >
                            <p className="mb-0" align="center">
                                {link.label}
                            </p>
                        </Link>
                    </Box>
                ))}
            </Box>
        </div>
    );
}

export default FinancialTemplates;
