import React from 'react';
import { Link } from 'react-router-dom';
import './FloatingIcon.css';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const FloatingIcon = ({ icon = faWhatsapp }) => {
    return (
        <Link
            to="https://wa.me/+966114532502"
            className="float-icon"
            rel="noreferrer"
            target="_blank"
        >
            <FontAwesomeIcon icon={icon} className="my-float" />
        </Link>
    );
};

export default FloatingIcon;
