import React from 'react';
import { Box, Link } from '@mui/material';

function GovLinks() {
    const links1 = [
        {
            label: 'ناجز وزارة العدل',
            url: 'https://najiz.sa',
        },
        {
            label: 'منصة التفتيش القضائي بالمجلس الأعلى للقضاء',
            url: 'https://www.scj.gov.sa/eservices/electroniccomplaint',
        },
        {
            label: 'لجنة الالتزام بإيقاع الغرامات المنصوص عليها في نظام الإعلام المرئي والمسموع',
            url: 'https://www.gamr.gov.sa/pages/33',
        },
        {
            label: 'المركز السعودي للتحكيم التجاري',
            url: 'https://sadr.org/',
        },
        {
            label: 'مركز التحكيم الرياضي السعودي',
            url: 'https://ssac.sa/',
        },
        {
            label: 'المركز السعودي للتحكيم العقاري',
            url: 'https://reac.sa/video/141',
        },
        {
            label: 'موقع لجان المنازعات والمخالفات المصرفية والتمويلية',
            url: 'https://www.bfc.gov.sa/',
        },
        {
            label: 'موقع الأمانة العامة للجان الفصل في المنازعات والمخالفات التأمينية',
            url: 'https://www.idc.gov.sa/ar-sa/Pages/eService.aspx',
        },
        {
            label: 'الأمانة العامة للجان الفصل في منازعات الأوراق المالية',
            url: 'https://www.crsd.org.sa/',
        },
        {
            label: 'التسوية الودية بوزارة الموارد البشرية',
            url: 'https://www.hrsd.gov.sa/ministry-services/services/269970',
        },
        {
            label: 'الامانة العامة للجان الضريبية',
            url: 'https://eservices.gstc.gov.sa',
        },
        {
            label: 'منصة تراضي وزارة العدل',
            url: 'https://taradhi.moj.gov.sa',
        },
        {
            label: 'بوابة خبرة وزارة العدل',
            url: 'https://khibrah.sa/',
        },
    ];
    const links2 = [
        {
            label: 'وزارة الداخلية',
            url: 'https://www.moi.gov.sa/',
        },
        {
            label: 'إمارة منطقة الرياض | الصفحة الرئيسية',
            url: 'https://www.riyadh.gov.sa/',
        },
        {
            label: 'بوابة تواصل الإلكترونية - إمارة منطقة مكة المكرمة',
            url: 'https://tawasul.makkah.gov.sa/inquiry',
        },
        {
            label: 'هيئة الرقابة ومكافحة الفساد - المملكة العربية السعودية',
            url: 'https://nazaha.gov.sa/',
        },
        {
            label: 'النيابة العامة',
            url: 'https://www.pp.gov.sa/',
        },
    ];
    const links3 = [
        {
            label: 'موقع هيئة الملكية الفكرية',
            url: 'https://www.saip.gov.sa/ar/',
        },
        {
            label: 'الهيئة السعودية للمحامين',
            url: 'https://sba.gov.sa/',
        },
        {
            label: 'بوابة أعمالي - غرفة الرياض',
            url: 'https://mybusiness.chamber.sa/',
        },
        {
            label: 'وزارة التجارة',
            url: 'https://mc.gov.sa/ar/pages/default.aspx',
        },
        {
            label: 'المركز السعودي للأعمال',
            url: 'https://ecr.mc.gov.sa/',
        },
        {
            label: 'منصة قوى',
            url: 'https://www.qiwa.sa/',
        },
        {
            label: 'منصة مدد اعمال',
            url: 'https://mudad.com.sa/',
        },
        {
            label: 'إدارة الخدمات الالكترونية لوزارة الموارد البشرية والتنمية الاجتماعية',
            url: 'https://www.mol.gov.sa/securessl/login.aspx',
        },
        {
            label: 'منصة ابشر',
            url: 'https://www.absher.sa/',
        },
        {
            label: 'خدمات النفاذ الوطني الموحد',
            url: 'https://www.iam.gov.sa/authservice/userauthservice?lang=ar',
        },
        {
            label: 'الموثق – وزارة العدل',
            url: 'https://mwathiq.sa/',
        },
        {
            label: 'مركز الإسناد والتصفية (إنفاذ)',
            url: 'https://www.googleadservices.com/pagead/aclk?sa=L&ai=DChcSEwjrjaH8_oCIAxXukYMHHY09NmQYABAAGgJlZg&co=1&ase=2&gclid=Cj0KCQjw2ou2BhCCARIsANAwM2FJEtbroq2mY9i3a8wsl0Ti8FjUa6K0_8EOA6Msdps_lJMC3Po-vSIaAo0dEALw_wcB&ohost=www.google.com&cid=CAESVuD2HHKMxYXYO8Af2qcCIn_DmnCTsMuVBHXsIpstahWimVMxw54nj5hTD5V-536406NFrlqSK0-3xnPeakBU3WD-ty8pydjx6MlK0QjM0zKPTK771yDt&sig=AOD64_1B9uswqV2ucvSpqs7XADtyfERypA&q&nis=4&adurl&ved=2ahUKEwiI2pn8_oCIAxVkgv0HHfVuJGcQ0Qx6BAgIEAE',
        },
        {
            label: 'منصة اعتماد',
            url: 'https://etimad.sa/',
        },
        {
            label: 'الهيئة العامة للمنشآت الصغيرة والمتوسطة | منشآت',
            url: 'https://www.monshaat.gov.sa/ar',
        },
        {
            label: 'هيئة الزكاة والضريبة والجمارك',
            url: 'https://zatca.gov.sa/ar/Pages/default.aspx',
        },
        {
            label: 'طلب تسجيل تصرف عقاري',
            url: 'https://zatca.gov.sa/ar/eServices/Pages/VatRequest.aspx',
        },
        {
            label: 'البورصة العقارية وزارة العدل | Real Estate Market',
            url: 'https://srem.moj.gov.sa/',
        },
        {
            label: 'بوابة مقيم | Muqeem Portal',
            url: 'https://muqeem.sa/',
        },
        {
            label: 'موقع وزارة العدل',
            url: 'https://www.moj.gov.sa/',
        },
        {
            label: 'معين ديوان المظالم',
            url: 'http://rserv1.bog.gov.sa/Eservices/Pages/default.aspx',
        },
        {
            label: 'موقع ديوان المظالم',
            url: 'https://www.bog.gov.sa/',
        },
        {
            label: 'موقع المجلس الأعلى للقضاء',
            url: 'https://www.scj.gov.sa/ ',
        },
    ];
    return (
        <div className="px-3 pb-5">
            <h3 className="text-center my-4">الجهات القضائية وشبهة قضائية</h3>
            <Box
                dir="ltr"
                display="flex"
                flexDirection="column"
                alignItems="center"
                gap={2}
                mt={4}
            >
                {links1.map((link, index) => (
                    <Box
                        dir="ltr"
                        key={index}
                        component="div"
                        sx={{
                            border: '1px solid #ccc',
                            borderRadius: '8px',
                            padding: '10px 20px',
                            width: '100%',
                            maxWidth: '450px',
                            backgroundColor: '#f5f5f5',
                            '&:hover': {
                                backgroundColor: '#e0e0e0',
                                boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
                            },
                            transition: 'all 0.3s ease',
                        }}
                    >
                        <Link
                            href={link.url}
                            underline="none"
                            color="primary"
                            target="_blank"
                        >
                            <p align="center" className="mb-0">
                                {link.label}
                            </p>
                        </Link>
                    </Box>
                ))}
            </Box>
            <h3 className="text-center my-4">الجهات الأمنية</h3>
            <Box
                dir="ltr"
                display="flex"
                flexDirection="column"
                alignItems="center"
                gap={2}
                mt={4}
            >
                {links2.map((link, index) => (
                    <Box
                        dir="ltr"
                        key={index}
                        component="div"
                        sx={{
                            border: '1px solid #ccc',
                            borderRadius: '8px',
                            padding: '10px 20px',
                            width: '100%',
                            maxWidth: '450px',
                            backgroundColor: '#f5f5f5',
                            '&:hover': {
                                backgroundColor: '#e0e0e0',
                                boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
                            },
                            transition: 'all 0.3s ease',
                        }}
                    >
                        <Link
                            href={link.url}
                            underline="none"
                            color="primary"
                            target="_blank"
                        >
                            <p align="center" className="mb-0">
                                {link.label}
                            </p>
                        </Link>
                    </Box>
                ))}
            </Box>
            <h3 className="text-center my-4">
                الجهات الحكومية للشؤون الإدارية
            </h3>
            <Box
                dir="ltr"
                display="flex"
                flexDirection="column"
                alignItems="center"
                gap={2}
                mt={4}
            >
                {links3.map((link, index) => (
                    <Box
                        dir="ltr"
                        key={index}
                        component="div"
                        sx={{
                            border: '1px solid #ccc',
                            borderRadius: '8px',
                            padding: '10px 20px',
                            width: '100%',
                            maxWidth: '450px',
                            backgroundColor: '#f5f5f5',
                            '&:hover': {
                                backgroundColor: '#e0e0e0',
                                boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
                            },
                            transition: 'all 0.3s ease',
                        }}
                    >
                        <Link
                            href={link.url}
                            underline="none"
                            color="primary"
                            target="_blank"
                        >
                            <p align="center" className="mb-0">
                                {link.label}
                            </p>
                        </Link>
                    </Box>
                ))}
            </Box>
        </div>
    );
}

export default GovLinks;
