import React from 'react';
import { Box, Link } from '@mui/material';

const SystemLinks = () => {
    const links = [
        {
            label: 'رابط اصدارات جمعية قضاء (النظام باللائحة التنفيذية)',
            url: 'https://qadha.org.sa/ar/books',
            sub: 'ملاحظة: الاصدارات للقراءة فقط يتعذر النسخ.',
        },
        {
            label: 'جمعية قضاء نظام المرافعات الشرعية ولوائحه التنفيذية مع الفهارس',
            url: 'https://qadha.org.sa/files/3/%D9%85%D8%B1%D9%83%D8%B2%20%D8%A7%D9%84%D8%A8%D8%AD%D9%88%D8%AB%20%D9%88%D8%A7%D9%84%D8%AF%D8%B1%D8%A7%D8%B3%D8%A7%D8%AA/%D9%86%D8%B8%D8%A7%D9%85%20%D8%A7%D9%84%D9%85%D8%B1%D8%A7%D9%81%D8%B9%D8%A7%D8%AA%20%D8%A7%D9%84%D8%B4%D8%B1%D8%B9%D9%8A%D8%A9%20%D9%88%D9%84%D9%88%D8%A7%D8%A6%D8%AD%D9%87%20%D8%A7%D9%84%D8%AA%D9%86%D9%81%D9%8A%D8%B0%D9%8A%D8%A9%20%D9%85%D8%B9%20%D8%A7%D9%84%D9%81%D9%87%D8%A7%D8%B1%D8%B3.pdf',
        },
        {
            label: 'جمعية قضاء نظام العمل ولوائحه التنفيذية مع الفهارس',
            url: 'https://qadha.org.sa/files/3/%D9%85%D8%B1%D9%83%D8%B2%20%D8%A7%D9%84%D8%A8%D8%AD%D9%88%D8%AB%20%D9%88%D8%A7%D9%84%D8%AF%D8%B1%D8%A7%D8%B3%D8%A7%D8%AA/%D9%86%D8%B8%D8%A7%D9%85%20%D8%A7%D9%84%D8%B9%D9%85%D9%84%20%D9%88%D9%84%D9%88%D8%A7%D8%A6%D8%AD%D9%87%20%D8%A7%D9%84%D8%AA%D9%86%D9%81%D9%8A%D8%B0%D9%8A%D8%A9.pdf',
        },
        {
            label: 'جمعية قضاء نظام الإجراءات الجزائية ولائحته التنفيذية مع الفهارس',
            url: 'https://qadha.org.sa/files/3/%D9%85%D8%B1%D9%83%D8%B2%20%D8%A7%D9%84%D8%A8%D8%AD%D9%88%D8%AB%20%D9%88%D8%A7%D9%84%D8%AF%D8%B1%D8%A7%D8%B3%D8%A7%D8%AA/%D9%86%D8%B8%D8%A7%D9%85%20%D8%A7%D9%84%D8%A5%D8%AC%D8%B1%D8%A7%D8%A1%D8%A7%D8%AA%20%D8%A7%D9%84%D8%AC%D8%B2%D8%A7%D8%A6%D9%8A%D8%A9%20%D9%88%D9%84%D9%88%D8%A7%D8%A6%D8%AD%D9%87%20%D8%A7%D9%84%D8%AA%D9%86%D9%81%D9%8A%D8%B0%D9%8A%D8%A9.pdf',
        },
        {
            label: 'جمعية قضاء نظام المعاملات المدنية مع الفهارس',
            url: 'https://qadha.org.sa/files/shares/%D9%86%D8%B8%D8%A7%D9%85%20%D8%A7%D9%84%D9%85%D8%B9%D8%A7%D9%85%D9%84%D8%A7%D8%AA%20%D8%A7%D9%84%D9%85%D8%AF%D9%86%D9%8A%D8%A9%20%D9%85%D8%B9%20%D8%A7%D9%84%D9%81%D9%87%D8%A7%D8%B1%D8%B3.pdf',
        },
        // {
        //     label: 'جمعية قضاء نظام الإفلاس ولوائحه وقواعده مع الفهارس',
        //     url: 'https://qadha.org.sa/files/shares/%D9%86%D8%B8%D8%A7%D9%85%20%D8%A7%D9%84%D9%85%D9%86%D8%A7%D9%81%D8%B3%D8%A7%D8%AA%20%D9%88%D8%A7%D9%84%D9%85%D8%B4%D8%AA%D8%B1%D9%8A%D8%A7%D8%AA%20%D8%A7%D9%84%D8%AD%D9%83%D9%88%D9%85%D9%8A%D8%A9.pdf',
        // },
        {
            label: 'جمعية قضاء نظام الشركات ولوائحه التنفيذية مع الفهارس',
            url: 'https://qadha.org.sa/files/3/%D9%83%D8%AA%D8%A8%20%D9%82%D8%B6%D8%A7%D8%A1/%D9%86%D8%B8%D8%A7%D9%85%20%D8%A7%D9%84%D8%B4%D8%B1%D9%83%D8%A7%D8%AA%20%D9%88%D9%84%D9%88%D8%A7%D8%A6%D8%AD%D9%87%20%D8%A7%D9%84%D8%AA%D9%86%D9%81%D9%8A%D8%B0%D9%8A%D8%A9.pdf',
        },
        {
            label: 'جمعية قضاء نظام الإثبات وأدلته الإجرائية',
            url: 'https://qadha.org.sa/files/3/%D9%85%D9%84%D9%81%D8%A7%D8%AA%20%D8%A7%D9%84%D9%83%D8%AA%D8%A8%20%D8%A7%D9%84%D9%85%D8%B9%D8%AF%D9%84%D8%A9/%D9%86%D8%B8%D8%A7%D9%85%20%D8%A7%D9%84%D8%A5%D8%AB%D8%A8%D8%A7%D8%AA%20%D9%85%D8%B9%20%D8%A7%D9%84%D9%81%D9%87%D8%A7%D8%B1%D8%B3.pdf',
        },
        {
            label: 'جمعية قضاء نظام مكافحة المخدرات والمؤثرات العقلية ولائحته التنفيذية مع الفهارس',
            url: 'https://qadha.org.sa/files/shares/%D9%86%D8%B8%D8%A7%D9%85%20%D9%85%D9%83%D8%A7%D9%81%D8%AD%D8%A9%20%D8%A7%D9%84%D9%85%D8%AE%D8%AF%D8%B1%D8%A7%D8%AA%20%D9%88%D8%A7%D9%84%D9%85%D8%A4%D8%AB%D8%B1%D8%A7%D8%AA%20%D8%A7%D9%84%D8%B9%D9%82%D9%84%D9%8A%D8%A9%20%D9%88%D9%84%D8%A7%D8%A6%D8%AD%D8%AA%D9%87%20%D8%A7%D9%84%D8%AA%D9%86%D9%81%D9%8A%D8%B0%D9%8A%D8%A9.pdf',
        },
        {
            label: 'جمعية قضاء نظام حماية حقوق المؤلف ولائحته التنفيذية مع الفهارس عرض وترتيب مع نصوص من أحكام قضائية واتفاقيات ومعاهدات دولية',
            url: 'https://qadha.org.sa/files/shares/%D9%86%D8%B8%D8%A7%D9%85%20%D8%AD%D9%85%D8%A7%D9%8A%D8%A9%20%D8%AD%D9%82%D9%88%D9%82%20%D8%A7%D9%84%D9%85%D8%A4%D9%84%D9%81%20%D9%88%D9%84%D8%A7%D8%A6%D8%AD%D8%AA%D9%87%20%D8%A7%D9%84%D8%AA%D9%86%D9%81%D9%8A%D8%B0%D9%8A%D8%A9.pdf',
        },
        {
            label: 'جمعية قضاء نظام مكافحة التستر ولوائحه التنفيذية مع الفهارس',
            url: 'https://qadha.org.sa/files/shares/%D9%86%D8%B8%D8%A7%D9%85%20%D9%85%D9%83%D8%A7%D9%81%D8%AD%D8%A9%20%D8%A7%D9%84%D8%AA%D8%B3%D8%AA%D8%B1%20%D9%88%D9%84%D9%88%D8%A7%D8%A6%D8%AD%D9%87%20%D8%A7%D9%84%D8%AA%D9%86%D9%81%D9%8A%D8%B0%D9%8A%D8%A9.pdf',
        },
        {
            label: 'جمعية قضاء نظام الشركات ولوائحه التنفيذية مع الفهارس',
            url: 'https://qadha.org.sa/files/3/%D9%83%D8%AA%D8%A8%20%D9%82%D8%B6%D8%A7%D8%A1/%D9%86%D8%B8%D8%A7%D9%85%20%D8%A7%D9%84%D8%B4%D8%B1%D9%83%D8%A7%D8%AA%20%D9%88%D9%84%D9%88%D8%A7%D8%A6%D8%AD%D9%87%20%D8%A7%D9%84%D8%AA%D9%86%D9%81%D9%8A%D8%B0%D9%8A%D8%A9.pdf',
        },
        {
            label: 'جمعية قضاء نظام المنافسات والمشتريات الحكومية ولائحته التنفيذية مع الفهارس',
            url: 'https://qadha.org.sa/files/shares/%D9%86%D8%B8%D8%A7%D9%85%20%D8%A7%D9%84%D9%85%D9%86%D8%A7%D9%81%D8%B3%D8%A7%D8%AA%20%D9%88%D8%A7%D9%84%D9%85%D8%B4%D8%AA%D8%B1%D9%8A%D8%A7%D8%AA%20%D8%A7%D9%84%D8%AD%D9%83%D9%88%D9%85%D9%8A%D8%A9.pdf',
        },
        {
            label: 'جمعية قضاء نظام المساهمات العقارية ولائحته التنفيذية',
            url: 'https://qadha.org.sa/files/shares/%D9%86%D8%B8%D8%A7%D9%85%20%D8%A7%D9%84%D9%85%D8%B3%D8%A7%D9%87%D9%85%D8%A7%D8%AA%20%D8%A7%D9%84%D8%B9%D9%82%D8%A7%D8%B1%D9%8A%D8%A9%20%D9%88%D9%84%D8%A7%D8%A6%D8%AD%D8%AA%D9%87%20%D8%A7%D9%84%D8%AA%D9%86%D9%81%D9%8A%D8%B0%D9%8A%D8%A9.pdf',
        },
        {
            label: 'جمعية قضاء نظام نزع ملكية العقارات للمنفعة العامة ولوائحه التنفيذية',
            url: 'https://qadha.org.sa/files/shares/%D9%86%D8%B8%D8%A7%D9%85%20%D9%86%D8%B2%D8%B9%20%D9%85%D9%84%D9%83%D9%8A%D8%A9%20%D8%A7%D9%84%D8%B9%D9%82%D8%A7%D8%B1%D8%A7%D8%AA%20%D9%84%D9%84%D9%85%D9%86%D9%81%D8%B9%D8%A9%20%D8%A7%D9%84%D8%B9%D8%A7%D9%85%D8%A9%20%D9%88%D9%84%D9%88%D8%A7%D8%A6%D8%AD%D9%87%20%D8%A7%D9%84%D8%AA%D9%86%D9%81%D9%8A%D8%B0%D9%8A%D8%A9.pdf',
        },
        {
            label: 'جمعية قضاء نظام الامتياز التجاري ولائحته التنفيذية وملحقاته ',
            url: 'https://qadha.org.sa/files/shares/%D9%86%D8%B8%D8%A7%D9%85%20%D8%A7%D9%84%D8%A7%D9%85%D8%AA%D9%8A%D8%A7%D8%B2%20%D8%A7%D9%84%D8%AA%D8%AC%D8%A7%D8%B1%D9%8A%20%D9%88%D9%84%D8%A7%D9%8A%D9%94%D8%AD%D8%AA%D9%87%20%D8%A7%D9%84%D8%AA%D9%86%D9%81%D9%8A%D8%B0%D9%8A%D8%A9%20%D9%88%D9%85%D9%84%D8%AD%D9%82%D8%A7%D8%AA%D9%87.pdf',
        },
        {
            label: 'جمعية قضاء نظام حماية البيانات الشخصية ولوائحه التنفيذية ',
            url: 'https://qadha.org.sa/files/shares/%D9%86%D8%B8%D8%A7%D9%85%20%D8%AD%D9%85%D8%A7%D9%8A%D8%A9%20%D8%A7%D9%84%D8%A8%D9%8A%D8%A7%D9%86%D8%A7%D8%AA%20%D8%A7%D9%84%D8%B4%D8%AE%D8%B5%D9%8A%D8%A9%20%D9%88%D9%84%D9%88%D8%A7%D9%8A%D9%94%D8%AD%D9%87%20%D8%A7%D9%84%D8%AA%D9%86%D9%81%D9%8A%D8%B0%D9%8A%D8%A9.pdf',
        },
        {
            label: 'جمعية قضاء نظام استئجار الدولة للعقار ولائحته التنفيذية ',
            url: 'https://qadha.org.sa/files/shares/%D9%86%D8%B8%D8%A7%D9%85%20%D8%A7%D8%B3%D8%AA%D9%8A%D9%94%D8%AC%D8%A7%D8%B1%20%D8%A7%D9%84%D8%AF%D9%88%D9%84%D8%A9%20%20%D9%84%D9%84%D8%B9%D9%82%D8%A7%D8%B1%20%D9%88%D9%84%D8%A7%D9%8A%D9%94%D8%AD%D8%AA%D9%87%20%D8%A7%D9%84%D8%AA%D9%86%D9%81%D9%8A%D8%B0%D9%8A%D8%A9.pdf',
        },
        {
            label: 'جمعية قضاء نظام التكاليف القضائية ولائحته التنفيذية',
            url: 'https://qadha.org.sa/files/3/%D9%83%D8%AA%D8%A8%20%D9%82%D8%B6%D8%A7%D8%A1/%D9%86%D8%B8%D8%A7%D9%85%20%D8%A7%D9%84%D8%AA%D9%83%D8%A7%D9%84%D9%8A%D9%81%20%D8%A7%D9%84%D9%82%D8%B6%D8%A7%D8%A6%D9%8A%D8%A9%20%D9%88%D9%84%D8%A7%D8%A6%D8%AD%D8%AA%D9%87%20%D8%A7%D9%84%D8%AA%D9%86%D9%81%D9%8A%D8%B0%D9%8A%D8%A9.pdf',
        },
        {
            label: 'رابط موقع هيئة الخبراء وما يصدره مجلس الوزارة من أنظمة ولوائح',
            url: 'https://laws.boe.gov.sa/BoeLaws/Laws/',
        },
        {
            label: 'رابط وزارة العدل للأنظمة واللوائح المرتبطة بها',
            url: 'https://laws.moj.gov.sa/',
        },
        {
            label: 'رابط ديوان المظالم للأنظمة واللوائح المرتبطة بها',
            url: 'https://www.bog.gov.sa/ScientificContent/JudicialSystems/Pages/default.aspx',
        },
        {
            label: 'رابط الأنظمة واللوائح وزارة التجارة المرتبطة بها',
            url: 'https://mc.gov.sa/ar/Regulations/Pages/default.aspx',
        },
        {
            label: 'رابط الأنظمة واللوائح هيئة الملكية الفكرية المرتبطة بها',
            url: 'https://www.saip.gov.sa/privacy-legislation/#regulations_and_regulations',
        },
        {
            label: 'رابط الأنظمة واللوائح النيابة العامة المرتبطة بها',
            url: 'https://www.pp.gov.sa/ar/documents-library',
        },
        {
            label: 'رابط ملف قرار النائب العام رقم (1) بتاريخ:1-1-1442هـ الخاص بالجرائم الموجبة للإيقاف',
            url: 'https://t.co/LOS6pJ0wor',
        },
        {
            label: 'رابط ايقونة الأنظمة بموقع مجلس التعاون الخليجي (الانظمة: حقوقي / ملكية فكرية / جزائي  / تجاري)',
            url: 'https://www.gcc-sg.org/ar-sa/CognitiveSources/Pages/LawsAndregulations.aspx',
        },
        {
            label: 'رابط موقع المركز الوطني للوثائق والمحفوظات',
            url: 'https://ncar.gov.sa/#welcome',
        },
        {
            label: 'رابط منصة وزارة العدل لـ التعاميم مركز البحث',
            url: 'https://portaleservices.moj.gov.sa/TameemPortal/TameemList.aspx',
        },
    ];

    return (
        <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            className="mx-3"
            gap={2}
            mt={4}
        >
            <h1>روابط أنظمة</h1>
            {links.map((link, index) => (
                <Box
                    key={index}
                    component="div"
                    sx={{
                        border: '1px solid #ccc',
                        borderRadius: '8px',
                        padding: '10px 20px',
                        width: '100%',
                        maxWidth: '450px',
                        backgroundColor: '#f5f5f5',
                        '&:hover': {
                            backgroundColor: '#e0e0e0',
                            boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
                        },
                        transition: 'all 0.3s ease',
                    }}
                >
                    <Link
                        href={link.url}
                        underline="none"
                        color="primary"
                        target="_blank"
                        align="center"
                        className="d-flex flex-column justify-content-center align-items-center"
                    >
                        <p>{link.label}</p>
                        {link.sub && <small>{link?.sub}</small>}
                    </Link>
                </Box>
            ))}
        </Box>
    );
};

export default SystemLinks;
