import React, { useState } from 'react';
import axios from 'axios';
import * as Yup from 'yup';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './CareersForm.css';
import { env } from '../../../env/env';

const CareersForm = () => {
    const [formData, setFormData] = useState({
        arabicName: '',
        englishName: '',
        nationality: '',
        idType: '',
        idNumber: '',
        idExpiry: '',
        highestDegree: '',
        universityDegree: '',
        universityName: '',
        collegeName: '',
        departmentName: '',
        graduationYear: '',
        previousExperience: '',
        legalCourses: '',
        computerCourses: '',
        applicationType: '',
        email: '',
        phone: '',
        additionalNotes: '',
        resume: null,
    });

    const [isSubmitting, setIsSubmitting] = useState(false); // Track whether the form is being submitted

    const [errors, setErrors] = useState({});

    // Yup validation schema with custom error messages in Arabic
    const validationSchema = Yup.object().shape({
        arabicName: Yup.string().required('الاسم باللغة العربية مطلوب'),
        englishName: Yup.string().required('الاسم باللغة الإنجليزية مطلوب'),
        nationality: Yup.string().required('الجنسية مطلوبة'),
        idType: Yup.string().required('نوع الهوية مطلوب'),
        idNumber: Yup.string().required('رقم الهوية مطلوب'),
        idExpiry: Yup.string().required('تاريخ انتهاء الهوية مطلوب'),
        highestDegree: Yup.string().required('أعلى درجة علمية مطلوبة'),
        universityDegree: Yup.string().required('درجة الشهادة الجامعية مطلوبة'),
        universityName: Yup.string().required('اسم الجامعة مطلوب'),
        collegeName: Yup.string().required('اسم الكلية مطلوب'),
        departmentName: Yup.string().required('اسم القسم مطلوب'),
        graduationYear: Yup.string().required('عام التخرج مطلوب'),
        previousExperience: Yup.string().required('الخبرات السابقة مطلوبة'),

        // Custom error message for number fields
        legalCourses: Yup.number()
            .typeError('عدد الدورات القانونية يجب أن يكون رقماً صحيحاً') // Custom message
            .required('عدد الدورات القانونية مطلوب'),

        computerCourses: Yup.number()
            .typeError('عدد دورات الحاسوب يجب أن يكون رقماً صحيحاً') // Custom message
            .required('عدد دورات الحاسوب مطلوب'),

        applicationType: Yup.string().required('نوع التقديم مطلوب'),
        email: Yup.string()
            .email('البريد الإلكتروني غير صالح')
            .required('البريد الإلكتروني مطلوب'),
        phone: Yup.string().required('رقم الجوال مطلوب'),
        additionalNotes: Yup.string(),
        resume: Yup.mixed().required('السيرة الذاتية مطلوبة'),
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleFileChange = (e) => {
        setFormData({
            ...formData,
            resume: e.target.files[0],
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitting(true); // Disable the button while processing

        try {
            // Validate form data on frontend
            await validationSchema.validate(formData, { abortEarly: false });
            setErrors({}); // Clear errors if validation passes

            // Prepare form data for submission
            const formDataToSend = new FormData();
            for (let key in formData) {
                formDataToSend.append(key, formData[key]);
            }

            // Submit to the backend
            const response = await axios.post(
                env.API_URL + '/careers',
                formDataToSend,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                }
            );
            if (response.status === 200) {
                toast.success('تم إرسال طلبك بنجاح!'); // Success message in Arabic
                setFormData({
                    // Clear the form
                    arabicName: '',
                    englishName: '',
                    nationality: '',
                    idType: '',
                    idNumber: '',
                    idExpiry: '',
                    highestDegree: '',
                    universityDegree: '',
                    universityName: '',
                    collegeName: '',
                    departmentName: '',
                    graduationYear: '',
                    previousExperience: '',
                    legalCourses: '',
                    computerCourses: '',
                    applicationType: '',
                    email: '',
                    phone: '',
                    additionalNotes: '',
                    resume: null,
                });
            }
            console.log(response.data);
            // Handle success message
        } catch (validationError) {
            if (validationError.inner) {
                // Yup validation errors
                const formErrors = {};
                validationError.inner.forEach((error) => {
                    formErrors[error.path] = error.message;
                });
                setErrors(formErrors);
            }
        } finally {
            setIsSubmitting(false); // Re-enable the button after request is finished
        }
    };

    return (
        <div className="container my-5">
            <h2>تقديم الطلب الوظيفي</h2>
            <form onSubmit={handleSubmit} className="row g-3">
                {/* Arabic Name */}
                <div className="col-md-6 mb-3">
                    <label htmlFor="arabicName" className="form-label">
                        الاسم باللغة العربية
                    </label>
                    <input
                        type="text"
                        className={`form-control ${
                            errors.arabicName ? 'is-invalid' : ''
                        }`}
                        id="arabicName"
                        name="arabicName"
                        value={formData.arabicName}
                        onChange={handleChange}
                    />
                    {errors.arabicName && (
                        <div className="invalid-feedback">
                            {errors.arabicName}
                        </div>
                    )}
                </div>

                {/* English Name */}
                <div className="col-md-6 mb-3">
                    <label htmlFor="englishName" className="form-label">
                        الاسم باللغة الإنجليزية
                    </label>
                    <input
                        type="text"
                        className={`form-control ${
                            errors.englishName ? 'is-invalid' : ''
                        }`}
                        id="englishName"
                        name="englishName"
                        value={formData.englishName}
                        onChange={handleChange}
                    />
                    {errors.englishName && (
                        <div className="invalid-feedback">
                            {errors.englishName}
                        </div>
                    )}
                </div>

                {/* Nationality */}
                <div className="col-md-6 mb-3">
                    <label htmlFor="nationality" className="form-label">
                        الجنسية
                    </label>
                    <input
                        type="text"
                        className={`form-control ${
                            errors.nationality ? 'is-invalid' : ''
                        }`}
                        id="nationality"
                        name="nationality"
                        value={formData.nationality}
                        onChange={handleChange}
                    />
                    {errors.nationality && (
                        <div className="invalid-feedback">
                            {errors.nationality}
                        </div>
                    )}
                </div>

                {/* ID Type */}
                <div className="col-md-6 mb-3">
                    <label htmlFor="idType" className="form-label">
                        نوع الهوية
                    </label>
                    <select
                        className={`form-select ${
                            errors.idType ? 'is-invalid' : ''
                        }`}
                        id="idType"
                        name="idType"
                        value={formData.idType}
                        onChange={handleChange}
                    >
                        <option value="">اختر نوع الهوية</option>
                        <option value="هوية وطنية سعودية">
                            هوية وطنية سعودية
                        </option>
                        <option value="هوية مقيم بالسعودية">
                            هوية مقيم بالسعودية
                        </option>
                        <option value="جواز غير سعودي">جواز غير سعودي</option>
                        <option value="هوية خليجي">هوية خليجي</option>
                    </select>
                    {errors.idType && (
                        <div className="invalid-feedback">{errors.idType}</div>
                    )}
                </div>

                {/* ID Number */}
                <div className="col-md-6 mb-3">
                    <label htmlFor="idNumber" className="form-label">
                        رقم الهوية
                    </label>
                    <input
                        type="text"
                        className={`form-control ${
                            errors.idNumber ? 'is-invalid' : ''
                        }`}
                        id="idNumber"
                        name="idNumber"
                        value={formData.idNumber}
                        onChange={handleChange}
                    />
                    {errors.idNumber && (
                        <div className="invalid-feedback">
                            {errors.idNumber}
                        </div>
                    )}
                </div>

                {/* ID Expiry */}
                <div className="col-md-6 mb-3">
                    <label htmlFor="idExpiry" className="form-label">
                        تاريخ انتهاء الهوية
                    </label>
                    <input
                        type="date"
                        className={`form-control ${
                            errors.idExpiry ? 'is-invalid' : ''
                        }`}
                        id="idExpiry"
                        name="idExpiry"
                        value={formData.idExpiry}
                        onChange={handleChange}
                    />
                    {errors.idExpiry && (
                        <div className="invalid-feedback">
                            {errors.idExpiry}
                        </div>
                    )}
                </div>

                {/* Highest Degree */}
                <div className="col-md-6 mb-3">
                    <label htmlFor="highestDegree" className="form-label">
                        أعلى درجة علمية
                    </label>
                    <input
                        type="text"
                        className={`form-control ${
                            errors.highestDegree ? 'is-invalid' : ''
                        }`}
                        id="highestDegree"
                        name="highestDegree"
                        value={formData.highestDegree}
                        onChange={handleChange}
                    />
                    {errors.highestDegree && (
                        <div className="invalid-feedback">
                            {errors.highestDegree}
                        </div>
                    )}
                </div>

                {/* University Degree */}
                <div className="col-md-6 mb-3">
                    <label htmlFor="universityDegree" className="form-label">
                        درجة الشهادة الجامعية
                    </label>
                    <input
                        type="text"
                        className={`form-control ${
                            errors.universityDegree ? 'is-invalid' : ''
                        }`}
                        id="universityDegree"
                        name="universityDegree"
                        value={formData.universityDegree}
                        onChange={handleChange}
                    />
                    {errors.universityDegree && (
                        <div className="invalid-feedback">
                            {errors.universityDegree}
                        </div>
                    )}
                </div>

                {/* University Name */}
                <div className="col-md-6 mb-3">
                    <label htmlFor="universityName" className="form-label">
                        اسم الجامعة
                    </label>
                    <input
                        type="text"
                        className={`form-control ${
                            errors.universityName ? 'is-invalid' : ''
                        }`}
                        id="universityName"
                        name="universityName"
                        value={formData.universityName}
                        onChange={handleChange}
                    />
                    {errors.universityName && (
                        <div className="invalid-feedback">
                            {errors.universityName}
                        </div>
                    )}
                </div>

                {/* College Name */}
                <div className="col-md-6 mb-3">
                    <label htmlFor="collegeName" className="form-label">
                        اسم الكلية
                    </label>
                    <input
                        type="text"
                        className={`form-control ${
                            errors.collegeName ? 'is-invalid' : ''
                        }`}
                        id="collegeName"
                        name="collegeName"
                        value={formData.collegeName}
                        onChange={handleChange}
                    />
                    {errors.collegeName && (
                        <div className="invalid-feedback">
                            {errors.collegeName}
                        </div>
                    )}
                </div>

                {/* Department Name */}
                <div className="col-md-6 mb-3">
                    <label htmlFor="departmentName" className="form-label">
                        اسم القسم
                    </label>
                    <input
                        type="text"
                        className={`form-control ${
                            errors.departmentName ? 'is-invalid' : ''
                        }`}
                        id="departmentName"
                        name="departmentName"
                        value={formData.departmentName}
                        onChange={handleChange}
                    />
                    {errors.departmentName && (
                        <div className="invalid-feedback">
                            {errors.departmentName}
                        </div>
                    )}
                </div>

                {/* Graduation Year */}
                <div className="col-md-6 mb-3">
                    <label htmlFor="graduationYear" className="form-label">
                        عام التخرج
                    </label>
                    <input
                        type="text"
                        className={`form-control ${
                            errors.graduationYear ? 'is-invalid' : ''
                        }`}
                        id="graduationYear"
                        name="graduationYear"
                        value={formData.graduationYear}
                        onChange={handleChange}
                    />
                    {errors.graduationYear && (
                        <div className="invalid-feedback">
                            {errors.graduationYear}
                        </div>
                    )}
                </div>

                {/* Previous Experience */}
                <div className="col-md-6 mb-3">
                    <label htmlFor="previousExperience" className="form-label">
                        الخبرات السابقة
                    </label>
                    <input
                        type="text"
                        className={`form-control ${
                            errors.previousExperience ? 'is-invalid' : ''
                        }`}
                        id="previousExperience"
                        name="previousExperience"
                        value={formData.previousExperience}
                        onChange={handleChange}
                    />
                    {errors.previousExperience && (
                        <div className="invalid-feedback">
                            {errors.previousExperience}
                        </div>
                    )}
                </div>

                {/* Number of Legal Courses */}
                <div className="col-md-6 mb-3">
                    <label htmlFor="legalCourses" className="form-label">
                        عدد الدورات القانونية
                    </label>
                    <input
                        type="text"
                        className={`form-control ${
                            errors.legalCourses ? 'is-invalid' : ''
                        }`}
                        id="legalCourses"
                        name="legalCourses"
                        value={formData.legalCourses}
                        onChange={handleChange}
                    />
                    {errors.legalCourses && (
                        <div className="invalid-feedback">
                            {errors.legalCourses}
                        </div>
                    )}
                </div>

                {/* Number of Computer Courses */}
                <div className="col-md-6 mb-3">
                    <label htmlFor="computerCourses" className="form-label">
                        عدد الدورات للحاسوب
                    </label>
                    <input
                        type="text"
                        className={`form-control ${
                            errors.computerCourses ? 'is-invalid' : ''
                        }`}
                        id="computerCourses"
                        name="computerCourses"
                        value={formData.computerCourses}
                        onChange={handleChange}
                    />
                    {errors.computerCourses && (
                        <div className="invalid-feedback">
                            {errors.computerCourses}
                        </div>
                    )}
                </div>

                {/* Application Type */}
                <div className="col-md-6 mb-3">
                    <label htmlFor="applicationType" className="form-label">
                        نوع التقديم
                    </label>
                    <select
                        className={`form-select ${
                            errors.applicationType ? 'is-invalid' : ''
                        }`}
                        id="applicationType"
                        name="applicationType"
                        value={formData.applicationType}
                        onChange={handleChange}
                    >
                        <option value="">اختر نوع التقديم</option>
                        <option value="تدريب تعاوني">تدريب تعاوني</option>
                        <option value="محامي متدرب">محامي متدرب</option>
                        <option value="محامي ممارس">محامي ممارس</option>
                        <option value="اداري تقني">اداري تقني</option>
                        <option value="اداري اعلام">اداري اعلام</option>
                        <option value="اداري عام">اداري عام</option>
                    </select>
                    {errors.applicationType && (
                        <div className="invalid-feedback">
                            {errors.applicationType}
                        </div>
                    )}
                </div>

                {/* Email */}
                <div className="col-md-6 mb-3">
                    <label htmlFor="email" className="form-label">
                        البريد الإلكتروني
                    </label>
                    <input
                        type="email"
                        className={`form-control ${
                            errors.email ? 'is-invalid' : ''
                        }`}
                        id="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                    />
                    {errors.email && (
                        <div className="invalid-feedback">{errors.email}</div>
                    )}
                </div>

                {/* Phone */}
                <div className="col-md-6 mb-3">
                    <label htmlFor="phone" className="form-label">
                        رقم الجوال
                    </label>
                    <input
                        type="text"
                        className={`form-control ${
                            errors.phone ? 'is-invalid' : ''
                        }`}
                        id="phone"
                        name="phone"
                        value={formData.phone}
                        onChange={handleChange}
                    />
                    {errors.phone && (
                        <div className="invalid-feedback">{errors.phone}</div>
                    )}
                </div>

                {/* Additional Notes */}
                <div className="col-md-12 mb-3">
                    <label htmlFor="additionalNotes" className="form-label">
                        ملاحظات إضافية
                    </label>
                    <textarea
                        className={`form-control ${
                            errors.additionalNotes ? 'is-invalid' : ''
                        }`}
                        id="additionalNotes"
                        name="additionalNotes"
                        value={formData.additionalNotes}
                        onChange={handleChange}
                    ></textarea>
                    {errors.additionalNotes && (
                        <div className="invalid-feedback">
                            {errors.additionalNotes}
                        </div>
                    )}
                </div>

                {/* Resume Upload */}
                <div className="col-md-6 mb-3">
                    <label htmlFor="resume" className="form-label">
                        إرفاق السيرة الذاتية
                    </label>
                    <input
                        type="file"
                        className={`form-control ${
                            errors.resume ? 'is-invalid' : ''
                        }`}
                        id="resume"
                        name="resume"
                        onChange={handleFileChange}
                    />
                    {errors.resume && (
                        <div className="invalid-feedback">{errors.resume}</div>
                    )}
                </div>

                {/* Submit Button */}
                <div className="col-12">
                    <button
                        type="submit"
                        className="btn btn-primary"
                        disabled={isSubmitting}
                    >
                        {isSubmitting ? 'جارٍ الإرسال...' : 'إرسال الطلب'}{' '}
                        {/* Change text when submitting */}
                    </button>
                </div>
            </form>

            {/* Toastify container */}
            <ToastContainer />
        </div>
    );
};

export default CareersForm;
