import React, { useEffect } from 'react';
import { Box, Link } from '@mui/material';

function LegalTemplates() {
    useEffect(() => {
        // Disable cache for this page
        window.history.pushState(null, null, window.location.href);
        window.addEventListener('popstate', () => {
            window.history.pushState(null, null, window.location.href);
        });
    }, []);

    const links1 = [
        {
            label: '(وورد) نموذج طلب تسجيل مشروع في منصة مشورة',
            url: '/assets/files/legal/1/نموذج طلب تسجيل دعوى جديدة في منصة مشورة المحامي الذكي 1446.docx.docx',
        },
        {
            label: '(بي دي اف) نموذج جدول عامل (يومي/اسبوعي)',
            url: '/assets/files/legal/1/نموذج الجول الاسبوعي واليوم للموظف.docx',
        },
        {
            label: '(وورد) تقرير اسبوعي لعميل دائم (اعداد ضابط اتصال مشورة)',
            url: '/assets/files/legal/1/تقرير اسبوعي عميل سنوي (1).docx',
        },
        {
            label: '(بي دي اف) نموذج خطاب تغطية لتقارير أسبوعية للمحامين موجه للمدير إدارة القضايا والمشاريع',
            url: '/assets/files/legal/1/خطاب تغطية لمحامي لـ تقارير اسبوعية.pdf',
        },
        {
            label: '(وورد) نموذج تقرير اسبوعي عن طلب تنفيذ',
            url: '/assets/files/legal/1/تقرير طلب تنفيذ.docx',
        },
        {
            label: '(وورد) نموذج تقرير اسبوعي عن أعمال الجهات الحكومية',
            url: '/assets/files/legal/1/تقرير مراجعة جهة حكومية.docx',
        },
        {
            label: '(وورد) نموذج تقرير اسبوعي عن أعمال جهات أمنية',
            url: '/assets/files/legal/1/تقرير جهات أمنية.docx',
        },
        {
            label: '(وورد) نموذج تقرير اسبوعي عن أعمال جهات النيابة العامة',
            url: '/assets/files/legal/1/تقرير مراجعة النيابة العامة.docx',
        },
        {
            label: '(وورد) نموذج متابعة معاملة',
            url: '/assets/files/legal/1/نموذج متابعة معاملة.docx',
        },
    ];
    const links8 = [
        {
            label: '(بي دي اف) هيكلة قسم القضايا والمحامين بادارة القضايا والمشاريع',
            url: '/assets/files/legal/8/ملف مشروع الاصدار الثاني  2 هيكلة قسم القضايا بادارة القضايا والمشاريع مشورة 1446.pdf',
        },
        {
            label: '(بي دي اف) الدليل الاجرائي لاستعمال منصة المحامي الذكي مشورة ',
            url: '/assets/files/legal/1/قرار الموافقة على الـ نموذج رقم 757 الدليل الاجرائي لاستعمال القانوني لمنصة المحامي الذكي في منشأة .pdf',
        },
        {
            label: '(بي دي اف) قواعد اعمال القانونيين (يقدم الدليل على القواعد وتبلغ الإدارة)',
            url: '/assets/files/legal/1/قواعد عمل القانوننين الاصدار الرابع (1).pdf',
        },
        {
            label: '(بي دي اف) الدليل الارشادي لفريق التنفيذ والتحصيل',
            url: '/assets/files/legal/1/فريق التنفيذ والتحصيل الدليل الارشادي.pdf',
        },
        {
            label: '(بي دي اف) الدليل الارشادي لاعمال فريق القضايا الجزائية وفريق الاعمال التكليفية لدى الجهات الحكومية',
            url: '/assets/files/legal/8/الدليل الارشادي لأعمال فريق القضايا الجزائية وفريق الاعمال التكليفية لدى الجهات الحكومية.pdf',
        },
        {
            label: '(بي دي اف) الدليل الارشادي لفريق المشاريع التشغيلية للدعم الحكومية',
            url: '/assets/files/legal/8/الدليل الارشادي لأعمال الدليل الارشادي لفريق المشاريع التشغيلية للدعم الحكومية .pdf',
        },
    ];
    const links2 = [
        {
            label: '(وورد) نموذج نص تذكرة التواصل العدلي 1950',
            url: '/assets/files/legal/2/نموذج تذكرة التواصل العدلي 1950.docx',
        },
        {
            label: '(وورد) نموذج نص الشكوى لدى منصة التفتيش القضائي',
            url: '/assets/files/legal/2/شكوى التفتيش القضائي نموذج.docx',
        },
        {
            label: '(وورد) نموذج خطاب لأمارة منطقة',
            url: '/assets/files/legal/2/نموذج خطاب لامارة منطقة.docx',
        },
        {
            label: '(وورد) نموذج خطاب يقدم للنيابة العامة',
            url: '/assets/files/legal/2/نموذج خطاب النيابة العامة.docx',
        },
        {
            label: '(وورد) نموذج خطاب يقدم لهيئة الرقابة ومكافحة الفساد',
            url: '/assets/files/legal/2/نموذج خطاب هيئة الرقابة ومكافحة الفساد.docx',
        },
        {
            label: '(وورد) نموذج (بلاغ/خطاب) يقدم لمركز الشرطة',
            url: '/assets/files/legal/2/نمذوج خطاب مركز شرطة.docx',
        },
        {
            label: '(وورد) نموذج خطاب يقدم لجهة لحكومية',
            url: '/assets/files/legal/2/نموذج خطاب لجهة حكومية خارجية.docx',
        },
        {
            label: '(وورد) نموذج خطاب يقدم لجهة خاصة ',
            url: '/assets/files/legal/2/نموذج خطاب لجهة خاصة.docx',
        },
    ];
    const links3 = [
        {
            label: '(وورد) نموذج تحرير دعوى حقوقية عامة',
            url: '/assets/files/legal/3/مذكرة تحرير دعوى حقوقية ترفق في صحيفة دعوى.docx',
        },
        {
            label: '(وورد) نموذج تحرير دعوى حقوقية أحوال شخصية',
            url: '/assets/files/legal/3/مذكرة تحرير دعوى أحوال شخصية ترفق في صحيفة الدعوى.docx',
        },
        {
            label: '(وورد) نموذج تحرير دعوى حقوقية عمالية',
            url: '/assets/files/legal/3/مذكرة تحرير دعوى عمالية ترفق في صحيفة الدعوى.docx',
        },
        {
            label: '(وورد) نموذج تحرير دعوى حقوقية منازعة تنفيذ',
            url: '/assets/files/legal/3/مذكرة تحرير دعوى منازعة تنفيذ ترفق في صحيفة الدعوى.docx',
        },
        {
            label: '(وورد) نموذج تحرير دعوى تجارية',
            url: '/assets/files/legal/3/مذكرة تحرير دعوى تجارية ترفق في صحيفة الدعوى.docx',
        },
        {
            label: '(وورد) نموذج تحرير دعوى إدارية',
            url: '/assets/files/legal/3/مذكرة تحرير دعوى إدارية ترفق في صحيفة الدعوى.docx',
        },
        {
            label: '(وورد) نموذج تحرير دعوى جزائية',
            url: '/assets/files/legal/3/مذكرة تحرير دعوى جزائية حق خاص ترفق في صحيفة الدعوى.docx',
        },
    ];
    const links4 = [
        {
            label: '(وورد) نموذج مذكرة الدفاع الأولى لدعوى حقوقية عامة',
            url: '/assets/files/legal/4/مذكرة الدفاع الأولى لدعوى عامة حقوقية ترفق في ملف القضية بمنصة وزارة العدل قبل الجلسة الاولى.docx',
        },
        {
            label: '(وورد) نموذج مذكرة الدفاع الأولى لدعوى حقوقية أحوال شخصية',
            url: '/assets/files/legal/4/مذكرة الدفاع الأولى لدعوى أحوال شخصية ترفق في ملف القضية بمنصة وزارة العدل قبل الجلسة الاولى.docx',
        },
        {
            label: '(وورد) نموذج مذكرة الدفاع الأولى لدعوى حقوقية عمالية',
            url: '/assets/files/legal/4/مذكرة الدفاع الأولى لدعوى العمالية ترفق في ملف القضية بمنصة وزارة العدل قبل الجلسة الاولى.docx',
        },
        {
            label: '(وورد) نموذج مذكرة الدفاع الأولى لدعوى حقوقية منازعة تنفيذ',
            url: '/assets/files/legal/4/مذكرة الدفاع الأولى لدعوى منازعة تنفيذ ترفق في ملف القضية بمنصة وزارة العدل قبل الجلسة الاولى.docx',
        },
        {
            label: '(وورد) نموذج مذكرة الدفاع الأولى لدعوى تجارية',
            url: '/assets/files/legal/4/مذكرة الدفاع الاولى تجارية.docx',
        },
        {
            label: '(وورد) نموذج مذكرة الدفاع الأولى لدعوى إدارية',
            url: '/assets/files/legal/4/مذكرة الدفاع الأولى لدعوى إدارية ترفق في ملف القضية بمنصة وزارة العدل قبل الجلسة الاولى.docx',
        },
        {
            label: '(وورد) نموذج مذكرة الدفاع الأولى لدعوى جزائية',
            url: '/assets/files/legal/4/مذكرة الدفاع الأولى لدعوى جزائية ترفق في ملف القضية بمنصة وزارة العدل قبل الجلسة الاولى.docx',
        },
        {
            label: '(وورد) نموذج مذكرة طلب اطلاع على مستندات دعوى جزائية',
            url: '/assets/files/legal/4/نموذج طلب اطلاع على ملف قضية جزائية.docx',
        },
        {
            label: '(وورد) نموذج مذكرة طلب افراج عن موقوف دعوى جزائية',
            url: '/assets/files/legal/4/طلب افراج عن متهم دعوى جزائية ترفق في ملف القضية بمنصة وزارة العدل قبل الجلسة الاولى.docx',
        },
        {
            label: '(وورد) نموذج مذكرة الرد على ادلة دعوى جزائية',
            url: '/assets/files/legal/4/مذكرة رد على ادلة بدعوى جزائية ترفق في ملف القضية بمنصة وزارة العدل قبل الجلسة الاولى.docx',
        },
        {
            label: '(وورد) نموذج مذكرة طلب عقد جلسة حضورية',
            url: '/assets/files/legal/4/مذكرة طلب عقد جلسة حضورية.docx',
        },
        {
            label: '(وورد) نموذج مذكرة جوابية',
            url: '/assets/files/legal/4/مذكرة جوابية.docx',
        },
    ];
    const links5 = [
        {
            label: 'استئناف حقوقي معجل',
            url: '/assets/files/legal/نموذج 1 . استئناف حقوقي معجل.docx',
        },
        {
            label: 'استئناف حقوقي عادي',
            url: '/assets/files/legal/نموذج رقم 2 استئناف حقوقي عادي.docx',
        },
        {
            label: 'استئناف اداري معجل',
            url: '/assets/files/legal/نموذج رقم 6 استئناف اداري معجل.docx',
        },
        {
            label: 'استئناف اداري عادي',
            url: '/assets/files/legal/نموذج رقم 5 استئناف اداري عادي.docx',
        },
        {
            label: 'استئناف تجاري معجل',
            url: '/assets/files/legal/نموذج استئناف تجاري معجل رقم 4.docx',
        },
        {
            label: 'استئناف تجاري عادي',
            url: '/assets/files/legal/نموذج استئناف تجاري رقم 3 عادي.docx',
        },
        {
            label: 'استئناف جزائي',
            url: '/assets/files/legal/نموذج رقم 7 استئناف جزائي.docx',
        },
    ];
    const links6 = [
        {
            label: 'الاعتراض بطلب النقض حقوقي',
            url: '/assets/files/legal/نموذج رقم 3 نقض عليا حقوقي.docx',
        },
        {
            label: 'الاعتراض بطلب النقض اداري',
            url: '/assets/files/legal/نموذج رقم 1 نقض اداري.docx',
        },
        {
            label: 'الاعتراض بطلب النقض تجاري',
            url: '/assets/files/legal/نموذج رقم 2 نقض تجاري.docx',
        },
        {
            label: 'الاعتراض بطلب النقض جزائي',
            url: '/assets/files/legal/نموذج رقم 4 طبلب نقض جزائي.docx',
        },
    ];
    const links7 = [
        {
            label: 'الاعتراض بطلب الالتماس إعادة النظر حقوقي',
            url: '/assets/files/legal/نموذج رقم 2 التماس حقوقي.docx',
        },
        {
            label: 'الاعتراض بطلب الالتماس إعادة النظر اداري',
            url: '/assets/files/legal/نموذج رقم 4 التماس اعادة نظر اداري.docx',
        },
        {
            label: 'الاعتراض بطلب الالتماس إعادة النظر تجاري',
            url: '/assets/files/legal/نموذج رقم 1 التماس اعادة نظر تجاري.docx',
        },
        {
            label: 'الاعتراض بطلب الالتماس إعادة النظر جزائي',
            url: '/assets/files/legal/نموذج رقم 3 التماس جزائي.docx',
        },
    ];

    return (
        <div className="px-3 pb-5">
            <h1 className="text-center mb-3">نماذج إدارة القضايا والمشاريع</h1>
            <h3 className="text-center my-4">
                {links8.length} نماذج القانونيين
            </h3>
            <Box
                dir="ltr"
                display="flex"
                flexDirection="column"
                alignItems="center"
                gap={2}
                mt={4}
            >
                {links8.map((link, index) => (
                    <Box
                        dir="ltr"
                        key={index}
                        component="div"
                        sx={{
                            border: '1px solid #ccc',
                            borderRadius: '8px',
                            padding: '10px 20px',
                            width: '100%',
                            maxWidth: '450px',
                            backgroundColor: '#f5f5f5',
                            '&:hover': {
                                backgroundColor: '#e0e0e0',
                                boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
                            },
                            transition: 'all 0.3s ease',
                        }}
                    >
                        <Link
                            href={link.url}
                            underline="none"
                            color="primary"
                            target="_blank"
                        >
                            <p className="mb-0" align="center">
                                {link.label}
                            </p>
                        </Link>
                    </Box>
                ))}
            </Box>
            <h3 className="text-center my-4">{links1.length} نماذج إدارية</h3>
            <Box
                dir="ltr"
                display="flex"
                flexDirection="column"
                alignItems="center"
                gap={2}
                mt={4}
            >
                {links1.map((link, index) => (
                    <Box
                        dir="ltr"
                        key={index}
                        component="div"
                        sx={{
                            border: '1px solid #ccc',
                            borderRadius: '8px',
                            padding: '10px 20px',
                            width: '100%',
                            maxWidth: '450px',
                            backgroundColor: '#f5f5f5',
                            '&:hover': {
                                backgroundColor: '#e0e0e0',
                                boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
                            },
                            transition: 'all 0.3s ease',
                        }}
                    >
                        <Link
                            href={link.url}
                            underline="none"
                            color="primary"
                            target="_blank"
                        >
                            <p className="mb-0" align="center">
                                {link.label}
                            </p>
                        </Link>
                    </Box>
                ))}
            </Box>

            <h3 className="text-center my-4">{links2.length} نماذج مخاطبات</h3>
            <Box
                dir="ltr"
                display="flex"
                flexDirection="column"
                alignItems="center"
                gap={2}
                mt={4}
            >
                {links2.map((link, index) => (
                    <Box
                        dir="ltr"
                        key={index}
                        component="div"
                        sx={{
                            border: '1px solid #ccc',
                            borderRadius: '8px',
                            padding: '10px 20px',
                            width: '100%',
                            maxWidth: '450px',
                            backgroundColor: '#f5f5f5',
                            '&:hover': {
                                backgroundColor: '#e0e0e0',
                                boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
                            },
                            transition: 'all 0.3s ease',
                        }}
                    >
                        <Link
                            href={link.url}
                            underline="none"
                            color="primary"
                            target="_blank"
                        >
                            <p className="mb-0" align="center">
                                {link.label}
                            </p>
                        </Link>
                    </Box>
                ))}
            </Box>
            <h3 className="text-center my-4">
                {links3.length} نماذج تحرير دعوى قضائية
            </h3>
            <Box
                dir="ltr"
                display="flex"
                flexDirection="column"
                alignItems="center"
                gap={2}
                mt={4}
            >
                {links3.map((link, index) => (
                    <Box
                        dir="ltr"
                        key={index}
                        component="div"
                        sx={{
                            border: '1px solid #ccc',
                            borderRadius: '8px',
                            padding: '10px 20px',
                            width: '100%',
                            maxWidth: '450px',
                            backgroundColor: '#f5f5f5',
                            '&:hover': {
                                backgroundColor: '#e0e0e0',
                                boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
                            },
                            transition: 'all 0.3s ease',
                        }}
                    >
                        <Link
                            href={link.url}
                            underline="none"
                            color="primary"
                            target="_blank"
                        >
                            <p className="mb-0" align="center">
                                {link.label}
                            </p>
                        </Link>
                    </Box>
                ))}
            </Box>
            <h3 className="text-center my-4">
                {links4.length} نماذج مذكرات قضائية قيد النظر
            </h3>
            <Box
                dir="ltr"
                display="flex"
                flexDirection="column"
                alignItems="center"
                gap={2}
                mt={4}
            >
                {links4.map((link, index) => (
                    <Box
                        dir="ltr"
                        key={index}
                        component="div"
                        sx={{
                            border: '1px solid #ccc',
                            borderRadius: '8px',
                            padding: '10px 20px',
                            width: '100%',
                            maxWidth: '450px',
                            backgroundColor: '#f5f5f5',
                            '&:hover': {
                                backgroundColor: '#e0e0e0',
                                boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
                            },
                            transition: 'all 0.3s ease',
                        }}
                    >
                        <Link
                            href={link.url}
                            underline="none"
                            color="primary"
                            target="_blank"
                        >
                            <p className="mb-0" align="center">
                                {link.label}
                            </p>
                        </Link>
                    </Box>
                ))}
            </Box>
            <h3 className="text-center my-4">الاعتراض بطلب الاستئناف</h3>
            <Box
                dir="ltr"
                display="flex"
                flexDirection="column"
                alignItems="center"
                gap={2}
                mt={4}
            >
                {links5.map((link, index) => (
                    <Box
                        dir="ltr"
                        key={index}
                        component="div"
                        sx={{
                            border: '1px solid #ccc',
                            borderRadius: '8px',
                            padding: '10px 20px',
                            width: '100%',
                            maxWidth: '450px',
                            backgroundColor: '#f5f5f5',
                            '&:hover': {
                                backgroundColor: '#e0e0e0',
                                boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
                            },
                            transition: 'all 0.3s ease',
                        }}
                    >
                        <Link
                            href={link.url}
                            underline="none"
                            color="primary"
                            target="_blank"
                        >
                            <p className="mb-0" align="center">
                                {link.label}
                            </p>
                        </Link>
                    </Box>
                ))}
            </Box>
            <h3 className="text-center my-4">الاعتراض بطلب النقض</h3>
            <Box
                dir="ltr"
                display="flex"
                flexDirection="column"
                alignItems="center"
                gap={2}
                mt={4}
            >
                {links6.map((link, index) => (
                    <Box
                        dir="ltr"
                        key={index}
                        component="div"
                        sx={{
                            border: '1px solid #ccc',
                            borderRadius: '8px',
                            padding: '10px 20px',
                            width: '100%',
                            maxWidth: '450px',
                            backgroundColor: '#f5f5f5',
                            '&:hover': {
                                backgroundColor: '#e0e0e0',
                                boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
                            },
                            transition: 'all 0.3s ease',
                        }}
                    >
                        <Link
                            href={link.url}
                            underline="none"
                            color="primary"
                            target="_blank"
                        >
                            <p className="mb-0" align="center">
                                {link.label}
                            </p>
                        </Link>
                    </Box>
                ))}
            </Box>
            <h3 className="text-center my-4">
                الاعتراض بطلب الالتماس إعادة النظر
            </h3>
            <Box
                dir="ltr"
                display="flex"
                flexDirection="column"
                alignItems="center"
                gap={2}
                mt={4}
            >
                {links7.map((link, index) => (
                    <Box
                        dir="ltr"
                        key={index}
                        component="div"
                        sx={{
                            border: '1px solid #ccc',
                            borderRadius: '8px',
                            padding: '10px 20px',
                            width: '100%',
                            maxWidth: '450px',
                            backgroundColor: '#f5f5f5',
                            '&:hover': {
                                backgroundColor: '#e0e0e0',
                                boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
                            },
                            transition: 'all 0.3s ease',
                        }}
                    >
                        <Link
                            href={link.url}
                            underline="none"
                            color="primary"
                            target="_blank"
                        >
                            <p className="mb-0" align="center">
                                {link.label}
                            </p>
                        </Link>
                    </Box>
                ))}
            </Box>
        </div>
    );
}

export default LegalTemplates;
