import React, { useEffect, useState } from 'react';
import { fetchUser } from '../services/FetchUser';

const withAuthenticatedGuard = (WrappedComponent) => {
    return (props) => {
        const [loading, setLoading] = useState(true);
        const [authenticated, setAuthenticated] = useState(false);

        useEffect(() => {
            const checkAuthenticated = async () => {
                const token = localStorage.getItem('token');
                if (token) {
                    try {
                        await fetchUser();

                        setAuthenticated(true);
                    } catch (error) {
                        console.error('Error fetching user:', error);
                    }
                }
                setLoading(false);
            };

            checkAuthenticated();
        }, []);

        if (loading) {
            return (
                <div className="container">
                    <div className="row">
                        <div className="col-12 py-5">
                            <h1 className="text-center">
                                يتم التحقق من صلاحياتك...
                            </h1>
                        </div>
                    </div>
                </div>
            );
        }

        if (!authenticated) {
            return (
                <div className="container">
                    <div className="row">
                        <div className="col-12 py-5">
                            <h1 className="text-center">
                                ليس لديك صلاحية لزيارة هذه الصفحة
                            </h1>
                        </div>
                    </div>
                </div>
            );
        }

        return <WrappedComponent {...props} />;
    };
};

export default withAuthenticatedGuard;
