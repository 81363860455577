import React from 'react';
import './Footer.css';
import {
    faSnapchat,
    faTiktok,
    faXTwitter,
} from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
// import { HashLink } from 'react-router-hash-link';
import footerLeft from '../../images/footer-left.png';
import footerRight from '../../images/footer-right.png';
import { useTranslation } from 'react-i18next';

const Footer = () => {
    const { t, i18n } = useTranslation();
    return (
        <footer>
            <div
                className="upper py-5"
                style={{ backgroundColor: '#061E2E' }}
            >
                <div className="container px-4 px-lg-5">
                    <div className="row w-100">
                        <div
                            className="col-12 col-xl-4 py-4"
                            style={{
                                textAlign: i18n.language === 'en' && 'left',
                            }}
                        >
                            {/* <HashLink to="/#page-top">
                                <div className="mb-3">
                                    <img
                                        src={logo}
                                        alt="شركة مشورة للمحاماة"
                                        width="150"
                                    />
                                </div>
                            </HashLink> */}

                            <h3>{t('headquarter location')}</h3>
                            <Link
                                to="https://maps.app.goo.gl/PqbASR5frwFG6is49?g_st=iwb"
                                target="_blank"
                            >
                                <p style={{ lineHeight: '1.25rem' }}>
                                    {t('riyadh olaya')}
                                </p>
                                <p style={{ lineHeight: '1.25rem' }}>
                                    {t('verdun tower')}
                                </p>
                                <p style={{ lineHeight: '1.25rem' }}>
                                    {t('office floor')}
                                    <span
                                        style={{
                                            direction: 'ltr',
                                            fontFamily: 'Montserrat',
                                        }}
                                    >
                                        {' '}
                                        (608)
                                    </span>
                                </p>
                            </Link>
                            <p style={{ lineHeight: '1.25rem' }}>
                                {t('saturday thursday')} &nbsp;&nbsp;
                                <span style={{ fontFamily: 'Montserrat' }}>
                                    {' '}
                                    09:00 - 18:00
                                </span>
                            </p>
                            <Link to="tel:00966920006294">
                                <p
                                    style={{
                                        fontFamily: 'Montserrat',
                                        direction: 'ltr',
                                        lineHeight: '1.25rem',
                                        display: 'flex',
                                        flexDirection:
                                            i18n.language === 'en'
                                                ? ''
                                                : 'row-reverse',
                                    }}
                                >
                                    +966 9200 06294
                                </p>
                            </Link>
                            <Link to="mailto:info@mshora.com">
                                <p
                                    style={{
                                        fontFamily: 'Montserrat',
                                        direction: 'ltr',
                                        lineHeight: '1.25rem',
                                        display: 'flex',
                                        flexDirection:
                                            i18n.language === 'en'
                                                ? ''
                                                : 'row-reverse',
                                    }}
                                >
                                    info@mshora.com
                                </p>
                            </Link>
                        </div>
                        <div
                            className="col-12 col-xl-4 py-4"
                            style={{
                                textAlign: 'center',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                        >
                            <div>
                                <p
                                    className="company-statement"
                                    // style={{
                                    //     fontFamily: 'Tajawal',
                                    //     fontWeight: 'bold',
                                    //     textAlign: 'justify',
                                    //     maxWidth: '190px',
                                    //     fontSize: '0.78rem',
                                    // }}
                                >
                                    شـــــــركه وليد الشهري و شركـــائه مشورة
                                    للاستشارات الشرعية والقانونية
                                    والمحـــــــــــامـــــــــــاة
                                    والتـــــوثيق والتحكـــيم
                                    شـــــــــــــــــــــركــــــــــــــــــــة
                                    مهنــــــــــــــــيــــــــــــــة ذ.م.م
                                </p>
                            </div>

                            <div className="d-flex align-items-center justify-content-center w-100 mt-3">
                                <div className="mx-3">
                                    <Link
                                        to="https://x.com/mshora_com"
                                        target="_blank"
                                    >
                                        <FontAwesomeIcon
                                            icon={faXTwitter}
                                            size="xl"
                                            color="#fff"
                                        />
                                    </Link>
                                </div>
                                <div
                                    className={
                                        i18n.language === 'en' ? 'me-3' : 'ms-3'
                                    }
                                >
                                    <Link
                                        to="https://t.snapchat.com/MJ6QNCDo"
                                        target="_blank"
                                    >
                                        <FontAwesomeIcon
                                            icon={faSnapchat}
                                            size="xl"
                                            color="#fff"
                                        />
                                    </Link>
                                </div>
                                <div
                                    className={
                                        i18n.language === 'en' ? 'me-3' : 'ms-3'
                                    }
                                >
                                    <Link
                                        to="https://www.tiktok.com/@mshora_com"
                                        target="_blank"
                                    >
                                        <FontAwesomeIcon
                                            icon={faTiktok}
                                            size="xl"
                                            color="#fff"
                                        />
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-xl-4 py-4 d-flex justify-content-center justify-content-lg-end align-items-center">
                            <div className="location-map">
                                <div className="mapouter">
                                    <div className="gmap_canvas">
                                        <iframe
                                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3624.9310483846257!2d46.6815886!3d24.6948969!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e2f0343db550923%3A0xe505fd8c636d7c3f!2z2LTYsdmD2Kkg2YXYtNmI2LHYqSDZhNmE2YXYrdin2YXYp9ip!5e0!3m2!1sar!2ssa!4v1695930462639!5m2!1sar!2ssa"
                                            title="Mshora Company Location"
                                            style={{ border: 0 }}
                                            allowFullScreen=""
                                            loading="lazy"
                                            referrerPolicy="no-referrer-when-downgrade"
                                        ></iframe>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer-left text-start">
                    <img src={footerLeft} alt="" />
                </div>
                <div className="footer-right text-end">
                    <img src={footerRight} alt="" />
                </div>
            </div>
            <div className="lower">
                <div className="small text-center">
                    {t('all copyrights reserved')} -{' '}
                    <a href="https://mshora.com" style={{ color: '#07425e' }}>
                        {' '}
                        {t('mshora')}&copy;{' '}
                    </a>
                    <span style={{ fontFamily: 'Montserrat' }}>2023</span>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
