import React, { useEffect } from 'react';
import { Box, Link } from '@mui/material';

function ManagerialTemplates() {

    useEffect(() => {
        // Disable cache for this page
        window.history.pushState(null, null, window.location.href);
        window.addEventListener('popstate', () => {
            window.history.pushState(null, null, window.location.href);
        });
    }, []);
    
    const links1 = [
        {
            label: 'قرار شركاء نموذج رقم (ع/1) (وورد)',
            url: '/assets/files/managerial/1/قرار شركاء نموذج رقم ع1.docx',
        },
        {
            label: 'قرار جمعية عمومية نموذج رقم (ع/2) (وورد)',
            url: '/assets/files/managerial/1/قرار جمعية عمومية نموذج ع2.docx',
        },
        {
            label: 'قرار مدير عام نموذج رقم (ع/3) (وورد)',
            url: '/assets/files/managerial/1/قرار مدير عام نموذج رقم ع 3.docx',
        },
        {
            label: 'عقد اتعاب محاماة فردي نموذج رقم (ع/4) (وورد)',
            url: '/assets/files/managerial/1/عقد اتعاب محاماة فردي نموذج رقم ع 4.docx',
        },
        {
            label: 'عرض سعر عربي نموذج رقم (ع/5) (وورد)',
            url: '/assets/files/managerial/1/عرض سعر عربي نموذج رقم ع 5.docx',
        },
        {
            label: 'عرض سعر انجليزي نموذج رقم (ع/6) (وورد)',
            url: '/assets/files/managerial/1/عرض سعر انجليزي نموذج رقم ع6.docx',
        },
        {
            label: 'محضر رقم (9) الإدارة العامة نموذج رقم (ع/7) (وورد)',
            url: '/assets/files/managerial/1/محضر رقم (9) الإدارة العامة نموذج رقم ع 7.docx',
        },
        {
            label: 'محضر حصر فهرس المعاملات نموذج رقم (ع/8) (وورد)',
            url: '/assets/files/managerial/1/محضر حصر فهرس المعاملات نموذج رقم ع 8.docx',
        },
        {
            label: 'فهرس نماذج الشركة كاملة نموذج رقم (ع/9) (وورد)',
            url: '/assets/files/managerial/1/فهرس نماذج الشركة كاملة نموذج رقم ع 9.docx',
        },
        {
            label: 'عرض خدمات عام عربي انجليزي نموذج رقم (ع/10) (بيدي اف)',
            url: '/assets/files/managerial/1/عرض خدمات عام عربي انجليزي نموذج رقم ع 10 بي دي اف.pdf',
        },
        {
            label: 'عقد اتعاب محاماة سنوي نموذج رقم (ع/11) (وورد)',
            url: '/assets/files/managerial/1/عقد اتعاب محاماة سنوي نموذج رقم ع11.docx',
        },
        {
            label: 'ملف الشركة البروفايل نموذج رقم (ع/12) (بيدي اف)',
            url: '/assets/files/managerial/1/ملف الشركة البروفايل نموذج رقم ع 12.pdf',
        },
    ];

    const links2 = [
        {
            label: 'محضر رقم (9) الإدارة المالية (وورد)',
            url: '/assets/files/managerial/2/محضر رقم 9 الادارة المالية.docx',
        },
        {
            label: 'محضر اتصال بعميل نموذج رقم (م/1) (وورد)',
            url: '/assets/files/managerial/2/محضر اتصال المالية نموذج رقم م 1.docx',
        },
        {
            label: 'حصر اتعاب العملاء نموذج رقم (م/2) (وورد)',
            url: '/assets/files/managerial/2/حصر اتعاب العملاء نموذج رقم م 2.docx',
        },
        {
            label: 'فهرس إجراءات التحصيل نموذج رقم (م/3) (وورد)',
            url: '/assets/files/managerial/2/فهرس إجراءات التحصيل نموذج رقم م 3.docx',
        },
        {
            label: 'مطالبة مالية بالسداد نموذج رقم (م/4) (وورد)',
            url: '/assets/files/managerial/2/مطالبة مالية بالسداد نموذج رقم م 4.docx',
        },
        {
            label: 'سند لأمر نموذج رقم (م/5) (وورد)',
            url: '/assets/files/managerial/2/محضر سند لامر نموذج رقم م 5.docx',
        },
        {
            label: 'عهدة مالية نموذج رقم (م/6) (وورد)',
            url: '/assets/files/managerial/2/محضر عهدة مالية نموذج رقم م 6.docx',
        },
        {
            label: 'عهدة عينية نموذج رقم (م/7) (وورد)',
            url: '/assets/files/managerial/2/محضر عهدة عينية نموذج رقم م 7.docx',
        },
        {
            label: 'تسجيل أصل للمنشأة نموذج رقم (م/8) (وورد)',
            url: '/assets/files/managerial/2/نموذج تسجيل أصل للمنشأة نموذج رقم م 8 فهرس ممتلكات للمنشأة.docx',
        },
        {
            label: 'أمر صرف نموذج رقم (م/9) (وورد)',
            url: '/assets/files/managerial/2/محضر أمر صرف نموذج رقم م 9.docx',
        },
        {
            label: 'اتلاف أصل للمنشأة نموذج رقم (م/10) (وورد)',
            url: '/assets/files/managerial/2/اتلاف أصل للمنشأة نموذج رقم م 10.docx',
        },
        {
            label: 'بيع أصل للمنشأة نموذج رقم (م/11) (وورد)',
            url: '/assets/files/managerial/2/محضر بيع أصل للمنشأة نموذج رقم م 11.docx',
        },
        {
            label: 'سند قبض حال اشكال منصة دفتره نموذج رقم (م/12) (وورد)',
            url: '/assets/files/managerial/2/سند قبض حال اشكال منصة دفتره نموذج رقم م 12.docx',
        },
        {
            label: 'سند صرف حال اشكال منصة دفتره نموذج رقم (م/13) (وورد)',
            url: '/assets/files/managerial/2/محضر سند صرف حال اشكال منصة دفتره نموذج رقم م 13.docx',
        },
        {
            label: 'خطة التحصيل نموذج رقم (م/14) (بي دي اف)',
            // url: '/assets/files/managerial/2/',
            url:'#'
        },
        {
            label: 'نصوص رسائل التحصيل للعلاء نموذج رقم (م/15) (وورد)',
            // url: '/assets/files/managerial/2/',
            url:'#'
        },
    ];

    const links3 = [
        { label: 'خطاب لجهة حكومية نموذج رقم (ك/1) (وورد)', url: '' },
        { label: 'خطاب لــ بنك / مصرف نموذج رقم (ك/2) (وورد)', url: '' },
        { label: 'محضر نموذج رقم (9) علاقات حكومية ورسمية (وورد)', url: '' },
        { label: 'محضر اتصال نموذج رقم (ك/3) (وورد)', url: '' },
        { label: 'خطاب لجهة خاصة نموذج رقم (ك/4) (وورد)', url: '' },
        {
            label: 'فهرس حصر المستندات الحكومية نموذج رقم (ك/5) (وورد)',
            url: '',
        },
        {
            label: 'فهرس حصر المستندات الخاصة بمنسوبي المنشأة نموذج رقم (ك/6) (وورد)',
            url: '',
        },
    ];

    const links4 = [
        { label: 'محضر نموذج رقم (9) إدارة تقنية (وورد)', url: '' },
        { label: 'محضر اتصال نموذج رقم (ق/1) (وورد)', url: '' },
        {
            label: 'محضر اثبات حالة وتعديل في منصة نموذج رقم (ق/2) (وورد)',
            url: '',
        },
    ];

    const links5 = [
        { label: 'محضر نموذج رقم (9) إدارة اعلام (وورد)', url: '' },
        { label: 'محضر اثبات حالة نموذج رقم (ل/1) (وورد)', url: '' },
        { label: 'خطة شهري نموذج رقم (ل/2) (وورد)', url: '' },
        {
            label: 'فهرس حصر المنشورات من تصاميم نموذج رقم (ل/3) (وورد)',
            url: '',
        },
        { label: 'فهرس حصر المنشورات من نصوص نموذج رقم (ل/4) (وورد)', url: '' },
        {
            label: 'فهرس حصر حسابات التواصل الاجتماعي نموذج رقم (ل/5) (وورد)',
            url: '',
        },
        {
            label: 'فهرس حصر متعاوني الإدارات مع إدارة الاعلام نموذج رقم (ل/6) (وورد)',
            url: '',
        },
        {
            label: 'فهرس حصر المنشورات من مقاطع فديو نموذج رقم (ل/7) (وورد)',
            url: '',
        },
        {
            label: 'فهرس تقويم المنشورات واوقاتها والمكلفين بها نموذج رقم (ل/8) (وورد)',
            url: '',
        },
    ];

    const links6 = [
        { label: 'محضر نموذج رقم (9) إدارة تسويق (وورد)', url: '' },
        { label: 'محضر اثبات حالة نموذج رقم (س/1) (وورد)', url: '' },
        { label: 'خطة شهري نموذج رقم (س/2) (وورد)', url: '' },
        { label: 'محضر اتصال نموذج رقم (س/3) (وورد)', url: '' },
        {
            label: 'فهرس حصر شهر تسويق اعتيادي محتمل عميل نموذج رقم (س/4) (وورد)',
            url: '',
        },
        {
            label: 'فهرس حصر شهر تسويق رقمي محتمل عميل نموذج رقم (س/5) (وورد)',
            url: '',
        },
        {
            label: 'فهرس حصر مراسلات محتمل عميل عبر البريد نموذج رقم (س/6) (وورد)',
            url: '',
        },
        {
            label: 'فهرس حصر التواصل مع محتمل عميل عبر اتصال نموذج رقم (س/7) (وورد)',
            url: '',
        },
        {
            label: 'تقرير شهري عن محتمل عميل وما تم التوصل اليه ومن صفى منهم نموذج رقم (س/8) (وورد)',
            url: '',
        },
        {
            label: 'خطاب تحويل محتمل عميل للسكرتير التنفيذي لإكمال إجراءات العقد نموذج رقم (س/9) (وورد)',
            url: '',
        },
        {
            label: 'فهرس حصر التواصل منسوبي المنشأة المتعاونين في العمل التسويقي نموذج رقم (س/10) (وورد)',
            url: '',
        },
    ];

    const links7 = [
        {
            label: 'محضر نموذج رقم (9) إدارة الجودة والمخاطر والارشفة والمحفوظات (وورد)',
            url: '',
        },
        { label: 'محضر استلام اصل مستندات نموذج رقم (ظ/1) (وورد)', url: '' },
        {
            label: 'محضر تقرير شهري عن إدارة وجودتها نموذج رقم (ظ/2) (وورد)',
            url: '',
        },
        {
            label: 'محضر تقرير شهري عن إدارة والمخاطر فيها نموذج رقم (ظ/3) (وورد)',
            url: '',
        },
        {
            label: 'فهرس حصر المستندات الورقية التي تم تسليمها خلال ثلاثة اشهر نموذج رقم (ظ/4) (وورد)',
            url: '',
        },
        {
            label: 'فهرس حصر المستندات الورقية في الإدارة وتحديثها نموذج رقم (ظ/5) (وورد)',
            url: '',
        },
        {
            label: 'فهرس حصر أصول المستندات الخاصة بالعملاء نموذج رقم (ظ/6) (وورد)',
            url: '',
        },
        { label: 'فهرس ملفات أصول المستندات نموذج رقم (ظ/7) (وورد)', url: '' },
        { label: 'محضر اثبات حالة نموذج رقم (ظ/8) (وورد)', url: '' },
        { label: 'خطاب للإدارة في المنشأة نموذج رقم (ظ/9) (وورد)', url: '' },
    ];

    const links8 = [
        {
            label: 'محضر نموذج رقم (9) إدارة الامن والسلامة والتشغيل والصيانة (وورد)',
            url: '',
        },
        {
            label: 'فهرس حصر كمرات المنشأة والخوادم التشغيلية لها وتحديثها نموذج رقم (ص/1) (وورد)',
            url: '',
        },
        {
            label: 'فهرس حصر أجهزة المنشأة صاحبة صيانة دورية وتحديثها نموذج رقم (ص/2) (وورد)',
            url: '',
        },
        {
            label: 'فهرس حصر سيارات المنشأة صاحبة صيانة دورية وتحديثها نموذج رقم (ص/3) (وورد)',
            url: '',
        },
        {
            label: 'محضر استلام اصل عهدة للصيانة نموذج رقم (ص/4) (وورد)',
            url: '',
        },
        {
            label: 'محضر اثبات حالة تشغيل لجميع المقرات نموذج رقم (ص/5) (وورد)',
            url: '',
        },
        {
            label: 'محضر اثبات صيانة لجميع الأصول الثابتة والمنقولة نموذج رقم (ص/6) (وورد)',
            url: '',
        },
        { label: 'محضر تقرير شهري عن الإدارة نموذج رقم (ص/7) (وورد)', url: '' },
    ];

    const links9 = [
        { label: 'محضر نموذج رقم (9) إدارة الفروع (وورد)', url: '' },
        {
            label: 'محضر توصية بمنسوب منشأة مدير فرع نموذج رقم (ف/1) (وورد)',
            url: '',
        },
        { label: 'خطاب لإدارة بـ المنشأة نموذج رقم (ف/2) (وورد)', url: '' },
        { label: 'محضر اثبات حالة نموذج رقم (ف/3) (وورد)', url: '' },
        { label: 'قرار تكليف نموذج رقم (ف/4) (وورد)', url: '' },
    ];

    const links10 = [
        { label: 'تقرير شهري عن قانوني نموذج رقم (ض/1) (وورد)', url: '' },
        { label: 'تقرير شهري للإدارة العامة نموذج رقم (ض/2) (وورد)', url: '' },
        { label: 'محضر اثبات حالة نموذج رقم (ض/3) (وورد)', url: '' },
        {
            label: 'محضر اثبات ابتكار او تعديل مشترك او خطط نموذج رقم (ض/4) (وورد)',
            url: '',
        },
        { label: 'قرار تكليف نموذج رقم (ض/5) (وورد)', url: '' },
        { label: 'محضر رقم (9) الإدارة القضايا والمشاريع (وورد)', url: '' },
    ];

    const links11 = [
        { label: 'عرض فني بور بوينت نموذج رقم (ض/6) (وورد)', url: '' },
        { label: 'عرض مالي بور بوينت نموذج رقم (ض/7) (وورد)', url: '' },
        {
            label: 'محضر تسجيل في منصة رقمية لتقديم خدمات قانونية نموذج رقم (ض/8) (وورد)',
            url: '',
        },
        {
            label: 'محضر تقديم على جهة لتقديم خدمات قانونية نموذج رقم (ض/9) (وورد)',
            url: '',
        },
        {
            label: 'جدول اعمال عرض الخدمات مدة شهر نموذج رقم (ض/10) (وورد)',
            url: '',
        },
        {
            label: 'محضر حصر منصات رقمية لتقديم عروض الخدمات نموذج رقم (ض/11) (وورد)',
            url: '',
        },
        {
            label: 'محضر حصر عميل محتمل لتقديم خدمات نموذج رقم (ض/12) (وورد)',
            url: '',
        },
        {
            label: 'محضر مشترك لترشيح منسوب قسم المشاريع من قسم القضايا والمحامين نموذج رقم (ض/13) (وورد)',
            url: '',
        },
        { label: 'محضر اثبات حالة نموذج رقم (ض/14) (وورد)', url: '' },
        {
            label: 'محضر اتصال بعميل او محتمل عميل نموذج رقم (ض/15) (وورد)',
            url: '',
        },
        {
            label: 'تقرير ضبط الاتصال مع العملاء نموذج رقم (ض/16) (وورد)',
            url: '',
        },
        {
            label: 'تقرير الشؤون الإدارة والسكرتارية القانونية نموذج رقم (ض/17) (وورد)',
            url: '',
        },
    ];
    return (
        <div className="px-3 pb-5">
            <h1 className="text-center mb-3">النماذج الإدارية</h1>
            <h3 className="text-center my-4">
                {links1.length} الإدارة العامة والسكرتارية التنفيذية
            </h3>
            <Box
                dir="ltr"
                display="flex"
                flexDirection="column"
                alignItems="center"
                gap={2}
                mt={4}
            >
                {links1.map((link, index) => (
                    <Box
                        dir="ltr"
                        key={index}
                        component="div"
                        sx={{
                            border: '1px solid #ccc',
                            borderRadius: '8px',
                            padding: '10px 20px',
                            width: '100%',
                            maxWidth: '450px',
                            backgroundColor: '#f5f5f5',
                            '&:hover': {
                                backgroundColor: '#e0e0e0',
                                boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
                            },
                            transition: 'all 0.3s ease',
                        }}
                    >
                        <Link
                            href={link.url}
                            underline="none"
                            color="primary"
                            target="_blank"
                        >
                            <p className="mb-0" align="center">
                                {link.label}
                            </p>
                        </Link>
                    </Box>
                ))}
            </Box>
            <h3 className="text-center my-4">
                {links2.length} الإدارة المالية
            </h3>
            <Box
                dir="ltr"
                display="flex"
                flexDirection="column"
                alignItems="center"
                gap={2}
                mt={4}
            >
                {links2.map((link, index) => (
                    <Box
                        dir="ltr"
                        key={index}
                        component="div"
                        sx={{
                            border: '1px solid #ccc',
                            borderRadius: '8px',
                            padding: '10px 20px',
                            width: '100%',
                            maxWidth: '450px',
                            backgroundColor: '#f5f5f5',
                            '&:hover': {
                                backgroundColor: '#e0e0e0',
                                boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
                            },
                            transition: 'all 0.3s ease',
                        }}
                    >
                        <Link
                            href={link.url}
                            underline="none"
                            color="primary"
                            target="_blank"
                        >
                            <p className="mb-0" align="center">
                                {link.label}
                            </p>
                        </Link>
                    </Box>
                ))}
            </Box>
            <h3 className="text-center my-4">
                {links3.length} إدارة شؤون العلاقات الحكومية والرسمية
            </h3>
            <Box
                dir="ltr"
                display="flex"
                flexDirection="column"
                alignItems="center"
                gap={2}
                mt={4}
            >
                {links3.map((link, index) => (
                    <Box
                        dir="ltr"
                        key={index}
                        component="div"
                        sx={{
                            border: '1px solid #ccc',
                            borderRadius: '8px',
                            padding: '10px 20px',
                            width: '100%',
                            maxWidth: '450px',
                            backgroundColor: '#f5f5f5',
                            '&:hover': {
                                backgroundColor: '#e0e0e0',
                                boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
                            },
                            transition: 'all 0.3s ease',
                        }}
                    >
                        <Link
                            href={link.url}
                            underline="none"
                            color="primary"
                            target="_blank"
                        >
                            <p className="mb-0" align="center">
                                {link.label}
                            </p>
                        </Link>
                    </Box>
                ))}
            </Box>
            <h3 className="text-center my-4">
                {links4.length} الإدارة التقنية
            </h3>
            <Box
                dir="ltr"
                display="flex"
                flexDirection="column"
                alignItems="center"
                gap={2}
                mt={4}
            >
                {links4.map((link, index) => (
                    <Box
                        dir="ltr"
                        key={index}
                        component="div"
                        sx={{
                            border: '1px solid #ccc',
                            borderRadius: '8px',
                            padding: '10px 20px',
                            width: '100%',
                            maxWidth: '450px',
                            backgroundColor: '#f5f5f5',
                            '&:hover': {
                                backgroundColor: '#e0e0e0',
                                boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
                            },
                            transition: 'all 0.3s ease',
                        }}
                    >
                        <Link
                            href={link.url}
                            underline="none"
                            color="primary"
                            target="_blank"
                        >
                            <p className="mb-0" align="center">
                                {link.label}
                            </p>
                        </Link>
                    </Box>
                ))}
            </Box>
            <h3 className="text-center my-4">{links5.length} إدارة الاعلام</h3>
            <Box
                dir="ltr"
                display="flex"
                flexDirection="column"
                alignItems="center"
                gap={2}
                mt={4}
            >
                {links5.map((link, index) => (
                    <Box
                        dir="ltr"
                        key={index}
                        component="div"
                        sx={{
                            border: '1px solid #ccc',
                            borderRadius: '8px',
                            padding: '10px 20px',
                            width: '100%',
                            maxWidth: '450px',
                            backgroundColor: '#f5f5f5',
                            '&:hover': {
                                backgroundColor: '#e0e0e0',
                                boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
                            },
                            transition: 'all 0.3s ease',
                        }}
                    >
                        <Link
                            href={link.url}
                            underline="none"
                            color="primary"
                            target="_blank"
                        >
                            <p className="mb-0" align="center">
                                {link.label}
                            </p>
                        </Link>
                    </Box>
                ))}
            </Box>
            <h3 className="text-center my-4">{links6.length} إدارة التسويق</h3>
            <Box
                dir="ltr"
                display="flex"
                flexDirection="column"
                alignItems="center"
                gap={2}
                mt={4}
            >
                {links6.map((link, index) => (
                    <Box
                        dir="ltr"
                        key={index}
                        component="div"
                        sx={{
                            border: '1px solid #ccc',
                            borderRadius: '8px',
                            padding: '10px 20px',
                            width: '100%',
                            maxWidth: '450px',
                            backgroundColor: '#f5f5f5',
                            '&:hover': {
                                backgroundColor: '#e0e0e0',
                                boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
                            },
                            transition: 'all 0.3s ease',
                        }}
                    >
                        <Link
                            href={link.url}
                            underline="none"
                            color="primary"
                            target="_blank"
                        >
                            <p className="mb-0" align="center">
                                {link.label}
                            </p>
                        </Link>
                    </Box>
                ))}
            </Box>
            <h3 className="text-center my-4">
                {links7.length} إدارة الجودة والمخاطر والارشفة والمحفوظات
            </h3>
            <Box
                dir="ltr"
                display="flex"
                flexDirection="column"
                alignItems="center"
                gap={2}
                mt={4}
            >
                {links7.map((link, index) => (
                    <Box
                        dir="ltr"
                        key={index}
                        component="div"
                        sx={{
                            border: '1px solid #ccc',
                            borderRadius: '8px',
                            padding: '10px 20px',
                            width: '100%',
                            maxWidth: '450px',
                            backgroundColor: '#f5f5f5',
                            '&:hover': {
                                backgroundColor: '#e0e0e0',
                                boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
                            },
                            transition: 'all 0.3s ease',
                        }}
                    >
                        <Link
                            href={link.url}
                            underline="none"
                            color="primary"
                            target="_blank"
                        >
                            <p className="mb-0" align="center">
                                {link.label}
                            </p>
                        </Link>
                    </Box>
                ))}
            </Box>
            <h3 className="text-center my-4">
                {links8.length} إدارة الأمن والسلامة والتشغيل والصيانة
            </h3>
            <Box
                dir="ltr"
                display="flex"
                flexDirection="column"
                alignItems="center"
                gap={2}
                mt={4}
            >
                {links8.map((link, index) => (
                    <Box
                        dir="ltr"
                        key={index}
                        component="div"
                        sx={{
                            border: '1px solid #ccc',
                            borderRadius: '8px',
                            padding: '10px 20px',
                            width: '100%',
                            maxWidth: '450px',
                            backgroundColor: '#f5f5f5',
                            '&:hover': {
                                backgroundColor: '#e0e0e0',
                                boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
                            },
                            transition: 'all 0.3s ease',
                        }}
                    >
                        <Link
                            href={link.url}
                            underline="none"
                            color="primary"
                            target="_blank"
                        >
                            <p className="mb-0" align="center">
                                {link.label}
                            </p>
                        </Link>
                    </Box>
                ))}
            </Box>
            <h3 className="text-center my-4">{links9.length} إدارة الفروع</h3>
            <Box
                dir="ltr"
                display="flex"
                flexDirection="column"
                alignItems="center"
                gap={2}
                mt={4}
            >
                {links9.map((link, index) => (
                    <Box
                        dir="ltr"
                        key={index}
                        component="div"
                        sx={{
                            border: '1px solid #ccc',
                            borderRadius: '8px',
                            padding: '10px 20px',
                            width: '100%',
                            maxWidth: '450px',
                            backgroundColor: '#f5f5f5',
                            '&:hover': {
                                backgroundColor: '#e0e0e0',
                                boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
                            },
                            transition: 'all 0.3s ease',
                        }}
                    >
                        <Link
                            href={link.url}
                            underline="none"
                            color="primary"
                            target="_blank"
                        >
                            <p className="mb-0" align="center">
                                {link.label}
                            </p>
                        </Link>
                    </Box>
                ))}
            </Box>
            <h3 className="text-center my-4">
                {links10.length + links11.length} إدارة القضايا والمشاريع
            </h3>
            <h5 className="text-center my-4">{ links10.length } مكتب مدير الإدارة</h5>
            <Box
                dir="ltr"
                display="flex"
                flexDirection="column"
                alignItems="center"
                gap={2}
                mt={4}
            >
                {links10.map((link, index) => (
                    <Box
                        dir="ltr"
                        key={index}
                        component="div"
                        sx={{
                            border: '1px solid #ccc',
                            borderRadius: '8px',
                            padding: '10px 20px',
                            width: '100%',
                            maxWidth: '450px',
                            backgroundColor: '#f5f5f5',
                            '&:hover': {
                                backgroundColor: '#e0e0e0',
                                boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
                            },
                            transition: 'all 0.3s ease',
                        }}
                    >
                        <Link
                            href={link.url}
                            underline="none"
                            color="primary"
                            target="_blank"
                        >
                            <p className="mb-0" align="center">
                                {link.label}
                            </p>
                        </Link>
                    </Box>
                ))}
            </Box>
            <h5 className="text-center my-4">
                {links11.length} مكتب نائب مدير الإدارة لشؤون المشاريع
            </h5>
            <Box
                dir="ltr"
                display="flex"
                flexDirection="column"
                alignItems="center"
                gap={2}
                mt={4}
            >
                {links11.map((link, index) => (
                    <Box
                        dir="ltr"
                        key={index}
                        component="div"
                        sx={{
                            border: '1px solid #ccc',
                            borderRadius: '8px',
                            padding: '10px 20px',
                            width: '100%',
                            maxWidth: '450px',
                            backgroundColor: '#f5f5f5',
                            '&:hover': {
                                backgroundColor: '#e0e0e0',
                                boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
                            },
                            transition: 'all 0.3s ease',
                        }}
                    >
                        <Link
                            href={link.url}
                            underline="none"
                            color="primary"
                            target="_blank"
                        >
                            <p className="mb-0" align="center">
                                {link.label}
                            </p>
                        </Link>
                    </Box>
                ))}
            </Box>
        </div>
    );
}

export default ManagerialTemplates;
