import React from 'react';
import { HashLink } from 'react-router-hash-link';
import {
    faScaleBalanced,
    faFileContract,
    faGavel,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './Home.css';
import Header from '../../components/Header/Header';

import halfLogo from '../../images/logos/half-logo.png';
import walid1 from '../../images/walid-lawyer1.webp';
import walid3 from '../../images/walid-lawyer3.webp';
import office1 from '../../images/office1.webp';
import office2 from '../../images/office2.webp';
import office3 from '../../images/office3.webp';
import office4 from '../../images/office4.webp';
import office5 from '../../images/office5.jpeg';
import office6 from '../../images/office6.jpeg';
import office7 from '../../images/office7.jpeg';
import office8 from '../../images/office8.jpeg';
import office9 from '../../images/office9.jpeg';
import office10 from '../../images/office10.jpeg';
import office11 from '../../images/office11.jpeg';
import target from '../../images/target.png';
import Carousel from '../../components/Carousel/Carousel';
import { useTranslation } from 'react-i18next';
import i18n from '../../i18n';

const Home = () => {
    const { t } = useTranslation();
    const carouselImages = [
        walid1,
        office4,
        office1,
        office2,
        office3,
        office5,
        office6,
        office7,
        office8,
        office9,
        office10,
        office11,
    ];
    return (
        <>
            <Header />
            <section
                className="bg-brand-secondary polygon-left pt-5"
                id="about"
                style={{ overflow: 'hidden' }}
            >
                <div className="container">
                    <div
                        className="row py-5"
                        //  style={{ direction: 'rtl' }}
                    >
                        <div className="col-12 col-lg-6 py-5 px-5">
                            <h2
                                style={{
                                    color: '#07425e',
                                    textAlign: 'justify',
                                }}
                            >
                                {t('about us')}
                            </h2>
                            <p
                                className="text-muted"
                                style={{ textAlign: 'justify' }}
                            >
                                {t('about us details')}
                            </p>
                        </div>
                        <div
                            className="col-12 col-lg-6 my-5 d-flex align-items-center"
                            style={{ position: 'relative' }}
                        >
                            <div
                                style={{
                                    borderRadius: '2rem',
                                }}
                            >
                                <div className="square"></div>
                                <img
                                    src={walid3}
                                    style={{
                                        maxWidth: '100%',
                                        overflow: 'overhidden',
                                    }}
                                    alt="مكتب مشورة للمحاماة"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div id="gallery">
                <Carousel images={carouselImages} />
            </div>
            <section className="page-section d-flex justify-content-center align-items-center">
                <div className="container">
                    <div className="row">
                        <div className="col-12 px-4 px-lg-5 text-center py-4">
                            <h2 className="mb-4 brand">
                                {t('you ask we answer')}
                            </h2>
                            <HashLink
                                className="btn btn-primary btn-xl mt-5"
                                to="/contact#"
                                style={{
                                    padding: '1rem 2rem',
                                    fontSize: '1.1rem',
                                    fontWeight: 400,
                                }}
                            >
                                {t('get consultation')}
                            </HashLink>
                        </div>
                    </div>
                </div>
            </section>
            <section className="page-section bg-brand-secondary polygon-left">
                <div className="container px-4 px-lg-5">
                    <div className="row gx-4 gx-lg-5 justify-content-center">
                        <div
                            className="col-lg-8"
                            style={{ textAlign: 'justify' }}
                        >
                            <h2 className="brand mt-0">{t('our goals')}</h2>
                            <p
                                className="mb-4 text-muted"
                                style={{
                                    textAlign: 'justify',
                                }}
                            >
                                {t('our goals details')}
                            </p>
                        </div>
                        <div className="col-4 target">
                            <img src={target} alt="" width={200} />
                        </div>
                    </div>
                </div>
            </section>
            <section id="services" className='pb-5 mb-5'>
                <div className="container pb-4">
                    <h2
                        className="mt-0 text-lg-center brand"
                        style={{ textAlign: 'justify' }}
                    >
                        {t('our services')}
                    </h2>
                    <div className="row gx-4 gx-lg-5">
                        <div className="col-lg-4 col-md-12 mt-5">
                            <div className="service">
                                <div className="mb-5 text-center">
                                    <FontAwesomeIcon
                                        icon={faFileContract}
                                        size="3x"
                                        color="#07425e"
                                    />
                                </div>
                                <h3 className="mb-2 text-center brand">
                                    {t('contract drafting')}
                                </h3>
                                <p
                                    className="text-muted mb-0"
                                    style={{ textAlign: 'justify' }}
                                >
                                    {t('contract drafting details')}
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-12 mt-5">
                            <div className="service">
                                <div className="mb-5 text-center">
                                    <FontAwesomeIcon
                                        icon={faScaleBalanced}
                                        size="3x"
                                        color="#07425e"
                                    />
                                </div>
                                <h3 className="mb-2 text-center brand">
                                    {t('legal advice')}
                                </h3>
                                <p
                                    className="text-muted mb-0"
                                    style={{ textAlign: 'justify' }}
                                >
                                    {t('legal advice details')}
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-12 mt-5">
                            <div className="service">
                                <div className="mb-5 text-center">
                                    <FontAwesomeIcon
                                        icon={faGavel}
                                        size="3x"
                                        color="#07425e"
                                    />
                                </div>
                                <h3 className="mb-2 text-center brand">
                                    {t('litigation and pleading')}
                                </h3>
                                <p
                                    className="text-muted mb-0"
                                    style={{ textAlign: 'justify' }}
                                >
                                    {t('litigation and pleading details')}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section
                className="d-flex justify-content-center align-items-center mb-5 pt-4 pb-5 text-start"
                dir="rtl"
            >
                <div className="container">
                    <div
                        className="row py-5"
                        style={{
                            boxShadow: 'rgba(0, 0, 0, 0.15) 0px 4px 24px',
                            position: 'relative',
                            minHeight: '400px',
                            overflow: 'hidden',
                        }}
                    >
                        <div
                            className="col-12 col-lg-8 px-4 px-lg-5 py-4 d-flex flex-column justify-content-center"
                            dir={`${i18n.language === 'ar' ? 'rtl' : 'ltr'}`}
                        >
                            <h2
                                className="mb-4 ps-lg-5"
                                style={{
                                    color: '#07425e',
                                    fontFamily: 'The Sans',
                                    textAlign: 'justify',
                                }}
                            >
                                {t('what sets us apart')}
                            </h2>
                            <p
                                className="text-muted ps-lg-5"
                                style={{ textAlign: 'justify' }}
                            >
                                {t('what sets us apart details')}
                            </p>
                        </div>
                        <div
                            className="half-logo"
                            style={{
                                position: 'absolute',
                                padding: 0,
                                margin: 0,
                                left: 0,
                                bottom: 0,
                            }}
                        >
                            <img src={halfLogo} alt="مشورة للمحاماة" />
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default Home;
