import axios from 'axios';
import { env } from '../env/env';

export const fetchUser = async () => {
    const token = localStorage.getItem('token');
    if (!token) {
        throw new Error('Token missing');
    }
    const response = await axios.get(env.API_URL + '/user', {
        headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
    });
    if (!response) {
        throw new Error('Failed to fetch user');
    }
    return response.data;
};
