import MultiCarousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

const responsive = {
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 3,
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2,
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
    },
};

const Carousel = (props) => {
    return (
        <MultiCarousel
            swipeable={false}
            draggable={false}
            showDots={false}
            responsive={responsive}
            ssr={false} // means to render carousel on server-side.
            infinite={true}
            autoPlay={props.deviceType !== 'mobile' ? true : false}
            autoPlaySpeed={3000}
            keyBoardControl={false}
            arrows={false}
            customTransition="all 2s ease"
            transitionDuration={2000}
            containerClass="carousel-container"
            removeArrowOnDeviceType={['tablet', 'mobile']}
            deviceType={props.deviceType}
            dotListClass="custom-dot-list-style"
            itemClass="carousel-item-padding-40-px"
            centerMode={true}
            rtl={true}
        >
            {props.images ? (
                props.images.map((image, index) => {
                    return (
                        <img
                            src={image}
                            style={{ maxWidth: '100%' }}
                            key={index}
                            alt={'mshora' + index}
                        />
                    );
                })
            ) : (
                <h1>Loading...</h1>
            )}
        </MultiCarousel>
    );
};

export default Carousel;
