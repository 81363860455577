import axios from 'axios';
import { env } from '../env/env';

export const fetchUserPermissions = async () => {
    try {
        const token = localStorage.getItem('token');
        if (!token) {
            return { permissions: [] };
        }
        const response = await axios.get(env.API_URL + '/user-permissions', {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
        });
        return response.data;
    } catch (error) {
        console.error('Error fetching user permissions:', error);
        localStorage.removeItem('token');

        return { permissions: [] };
    }
};
