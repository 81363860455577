import React, { useEffect, useState } from 'react';
import { fetchUserPermissions } from '../services/FetchUserPermissions';

const withPermissionGuard = (WrappedComponent, requiredPermissions = []) => {
    return (props) => {
        const [loading, setLoading] = useState(true);
        const [hasPermission, setHasPermission] = useState(false);

        useEffect(() => {
            const checkPermissions = async () => {
                const token = localStorage.getItem('token');
                if (token) {
                    try {
                        const response = await fetchUserPermissions();

                        const isAuthorized = requiredPermissions.every(
                            (perm) =>
                                response.permissions.includes(perm) ||
                                response.permissions.includes('All Form Fields')
                        );

                        setHasPermission(isAuthorized);
                    } catch (error) {
                        console.error('Error fetching permissions:', error);
                    }
                }
                setLoading(false);
            };

            checkPermissions();
        }, []);

        if (loading) {
            return (
                <div className="container">
                    <div className="row">
                        <div className="col-12 py-5">
                            <h1 className="text-center">
                                يتم التحقق من صلاحياتك...
                            </h1>
                        </div>
                    </div>
                </div>
            );
        }

        if (!hasPermission) {
            return (
                <div className="container">
                    <div className="row">
                        <div className="col-12 py-5">
                            <h1 className="text-center">
                                ليس لديك صلاحية لزيارة هذه الصفحة
                            </h1>
                        </div>
                    </div>
                </div>
            );
        }

        return <WrappedComponent {...props} />;
    };
};

export default withPermissionGuard;
