import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './Login.css'; // Import CSS file for styling
import { env } from '../../env/env';

function Login() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        // Check if token exists in localStorage
        const token = localStorage.getItem('token');

        if (token) {
            navigate('/');
        }
    }, [navigate]);
    const handleLogin = async (e) => {
        e.preventDefault();

        // Basic validation
        if (!email || !password) {
            setError('Please enter both email and password.');
            return;
        }

        try {
            // Fetch CSRF token
            await axios.get(env.APP_URL + '/sanctum/csrf-cookie', {
                withCredentials: true,
            });

            // Perform login
            const response = await axios.post(
                env.API_URL + '/login',
                { email, password },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        Accept: 'application/json',
                    },
                    withCredentials: true,
                }
            );

            const token = response.data.token; // Adjust according to your API response

            // Save the JWT in localStorage
            localStorage.setItem('token', token);

            // Redirect to the dashboard or another page
            window.location.href = '/';
        } catch (err) {
            // setError(err.response?.data?.message || 'Login failed. Please check your credentials.');
            setError('البريد الالكتروني او كلمة السر غير صحيحين');
        }
    };

    return (
        <div className="login-container py-5">
            <div className="login-form">
                <h2>تسجيل الدخول</h2>
                {error && <p className="error-message">{error}</p>}
                <form onSubmit={handleLogin}>
                    <div className="form-group">
                        <label htmlFor="email">الايميل:</label>
                        <input
                            id="email"
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="password">كلمة السر:</label>
                        <input
                            id="password"
                            type="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            required
                        />
                    </div>
                    <button type="submit" className="submit-button">
                        تسجيل الدخول
                    </button>
                </form>
            </div>
        </div>
    );
}

export default Login;
