import React from 'react';
import { Outlet } from 'react-router-dom';
import NavBar from '../components/NavBar/NavBar';
import Footer from '../components/Footer/Footer';
import FloatingIcon from '../components/FloatingIcon/FloatingIcon';

const Layout = () => {
    return (
        <>
            <NavBar />
            <Outlet />
            <FloatingIcon />
            <Footer />
        </>
    );
};

export default Layout;
