import React from 'react';
import { Box, Link } from '@mui/material';

function Platforms() {
    const links = [
        {
            label: 'منصة المحامي الذكي لعملاء مشورة',
            url: 'https://mshorh.smart-lawyer.net/Clients/',
        },
        {
            label: 'منصة المحامي الذكي محامين مشورة',
            url: 'https://mshorh.smart-lawyer.net/Dashboard',
        },
        {
            label: 'منصة دفتره المالية محاسبي مشورة',
            url: 'https://hajarlawyer.daftra.com/owner/expenses/add',
        },
        {
            label: 'منصة بيزات موارد بشرية جميع الموظفين',
            url: 'https://www.bayzat.com/enterprise/dashboard/',
        },
        {
            label: 'منصة التوقيع لمشورة سمول بيدي اف',
            url: 'https://smallpdf.com/ar/sign-pdf',
        },
        {
            label: 'منصة البريد الالكتروني لموظفين مشورة',
            url: 'https://mail.google.com/mail/u/0/#imp',
        },
    ];
    return (
        <div className="px-3 pb-5">
            <h3 className="text-center my-4">منصات مشورة</h3>
            <Box
                dir="ltr"
                display="flex"
                flexDirection="column"
                alignItems="center"
                gap={2}
                mt={4}
            >
                {links.map((link, index) => (
                    <Box
                        dir="ltr"
                        key={index}
                        component="div"
                        sx={{
                            border: '1px solid #ccc',
                            borderRadius: '8px',
                            padding: '10px 20px',
                            width: '100%',
                            maxWidth: '450px',
                            backgroundColor: '#f5f5f5',
                            '&:hover': {
                                backgroundColor: '#e0e0e0',
                                boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
                            },
                            transition: 'all 0.3s ease',
                        }}
                    >
                        <Link
                            href={link.url}
                            underline="none"
                            color="primary"
                            target="_blank"
                        >
                            <p align="center">{link.label}</p>
                        </Link>
                    </Box>
                ))}
            </Box>
        </div>
    );
}

export default Platforms;
