import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment from 'moment-hijri';
import { env } from '../../env/env';

const CompanyReportFormComponent = () => {
    const [formData, setFormData] = useState({
        preparerName: '',
        email: '',
        reportType: '',
        department: '',
        reportDate: '',
        hijriDate: '',
        reportTime: '',
        subject: '',
        copyWithGreeting: 'no',
        copyRecipientType: '',
        copyRecipientName: '',
        copyRecipientEmail: '',
        reportConnectionType: '',
        connectedPersonName: '',
        connectedPersonEmail: '',
        connectedPersonPhone: '',
        hrReportType: '',
        employeeName: '',
        employeeEmail: '',
        reportNature: '',
        reportDetails: '',
        recipients: [{ name: '', email: '', type: '' }],
    });

    const [attachments, setAttachments] = useState([]); // For storing attachments
    const [isSubmitting, setIsSubmitting] = useState(false);

    const handleFileChange = (e, index) => {
        const files = [...attachments];
        files[index] = e.target.files[0]; // Replace the file at the specified index
        setAttachments(files);
    };

    const addAttachmentField = () => {
        if (attachments.length < 5) {
            setAttachments([...attachments, null]); // Add a new file slot
        }
    };

    const removeAttachmentField = (index) => {
        const files = [...attachments];
        files.splice(index, 1); // Remove the file at the specified index
        setAttachments(files);
    };
    const handlePhoneChange = (e) => {
        const { name, value } = e.target;
        const validValue = value.replace(/[^0-9+]/g, ''); // Allow only numbers and "+" for international formats
        setFormData({ ...formData, [name]: validValue });
    };

    const handleChange = (e) => {
        const { name, value } = e.target;

        if (name === 'reportDate') {
            const hijriDate = moment(value, 'YYYY-MM-DD').format(
                'iYYYY/iMM/iDD'
            );
            setFormData({ ...formData, [name]: value, hijriDate });
        } else {
            setFormData({ ...formData, [name]: value });
        }
    };

    const handleRecipientChange = (index, e) => {
        const { name, value } = e.target;
        const newRecipients = [...formData.recipients];
        newRecipients[index][name] = value;
        setFormData({ ...formData, recipients: newRecipients });
    };

    const addRecipient = () => {
        setFormData({
            ...formData,
            recipients: [
                ...formData.recipients,
                { name: '', email: '', type: '' },
            ],
        });
    };

    const removeRecipient = (index) => {
        const newRecipients = [...formData.recipients];
        newRecipients.splice(index, 1);
        setFormData({ ...formData, recipients: newRecipients });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitting(true);
    
        try {
            const formDataToSend = new FormData();
    
            // Append simple fields
            for (const key in formData) {
                if (key === 'recipients' && Array.isArray(formData.recipients)) {
                    formData.recipients.forEach((recipient, index) => {
                        formDataToSend.append(`recipients[${index}][name]`, recipient.name.trim());
                        formDataToSend.append(`recipients[${index}][email]`, recipient.email.trim());
                        formDataToSend.append(`recipients[${index}][type]`, recipient.type);
                    });
                } else {
                    formDataToSend.append(key, formData[key]);
                }
            }
    
            // Append attachments
            attachments.forEach((file) => {
                if (file) formDataToSend.append('attachments[]', file);
            });
    
            // Send the request
            await axios.post(`${env.API_URL}/report`, formDataToSend, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            toast.success('تم إرسال المحضر بنجاح!');
        } catch (error) {
            console.error('Error response:', error.response);
    
            if (error.response && error.response.data.errors) {
                // Extract and show each error as a toast
                const errors = error.response.data.errors;
                Object.keys(errors).forEach((key) => {
                    errors[key].forEach((msg) => {
                        toast.error(msg); // Show each validation error
                    });
                });
            } else {
                toast.error(
                    'حدث خطأ أثناء إرسال المحضر. يرجى المحاولة لاحقًا.'
                );
            }
        } finally {
            setIsSubmitting(false);
        }
    };
    
    

    return (
        <div className="container my-5">
            <h2 className="text-center mb-4">نموذج إعداد المحضر</h2>
            <form
                onSubmit={handleSubmit}
                className="needs-validation row"
                noValidate
            >
                {/* Preparer Name */}
                <div className="mb-3 col-lg-6 col-12">
                    <label className="form-label">
                        معد المحضر (خانة إجبارية)
                    </label>
                    <input
                        type="text"
                        name="preparerName"
                        value={formData.preparerName}
                        onChange={handleChange}
                        className="form-control"
                        placeholder="أدخل اسم معد المحضر"
                        required
                    />
                </div>

                {/* Email */}
                <div className="mb-3 col-lg-6 col-12">
                    <label className="form-label">البريد الإلكتروني</label>
                    <input
                        type="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        className="form-control"
                        placeholder="أدخل البريد الإلكتروني"
                        required
                    />
                </div>

                {/* Report Type */}
                <div className="mb-3 col-lg-6 col-12">
                    <label className="form-label">
                        نوع المحضر (خانة إجبارية)
                    </label>
                    <select
                        name="reportType"
                        value={formData.reportType}
                        onChange={handleChange}
                        className="form-select"
                        required
                    >
                        <option value="">اختر نوع المحضر</option>
                        <option value="محضر مفتوح">محضر مفتوح</option>
                        <option value="محضر اثبات واقعة">
                            محضر اثبات واقعة
                        </option>
                        <option value="محضر اتصال">محضر اتصال</option>
                    </select>
                </div>

                {/* Department */}
                <div className="mb-3 col-lg-6 col-12">
                    <label className="form-label">الإدارة (خانة إجبارية)</label>
                    <select
                        name="department"
                        value={formData.department}
                        onChange={handleChange}
                        className="form-select"
                        required
                    >
                        <option value="">اختر الإدارة</option>
                        <option value="الإدارة العامة">الإدارة العامة</option>
                        <option value="الإدارة التقنية">الإدارة التقنية</option>
                        <option value="إدارة العلاقات الحكومية">
                            إدارة العلاقات الحكومية
                        </option>
                        <option value="إدارة الموارد البشرية">
                            إدارة الموارد البشرية
                        </option>
                        <option value="إدارة القضايا">إدارة القضايا</option>
                        <option value="إدارة المشاريع">إدارة المشاريع</option>
                        <option value="الإدارة المالية">الإدارة المالية</option>
                        <option value="إدارة الإعلام">إدارة الإعلام</option>
                    </select>
                </div>

                {/* Report Date */}
                <div className="mb-3 col-lg-6 col-12">
                    <label className="form-label">تاريخ المحضر</label>
                    <input
                        type="date"
                        name="reportDate"
                        value={formData.reportDate}
                        onChange={handleChange}
                        className="form-control"
                        required
                    />
                </div>
                {/* Report Time */}
                <div className="mb-3 col-lg-6 col-12">
                    <label className="form-label">وقت المحضر</label>
                    <input
                        type="time"
                        name="reportTime"
                        value={formData.reportTime}
                        onChange={handleChange}
                        className="form-control"
                        required
                    />
                </div>

                {/* Copy with Greeting */}
                <div className="mb-3 col-lg-6 col-12">
                    <label className="form-label">
                        هل يوجد نسخة مع التحية؟
                    </label>
                    <select
                        name="copyWithGreeting"
                        value={formData.copyWithGreeting}
                        onChange={handleChange}
                        className="form-select"
                        required
                    >
                        <option value="no">لا</option>
                        <option value="yes">نعم</option>
                    </select>
                </div>

                {/* Recipients */}
                {formData.copyWithGreeting === 'yes' &&
                    formData.recipients.map((recipient, index) => (
                        <div key={index} className="row mb-3">
                            <div className="col-lg-4 col-12">
                                <label className="form-label">
                                    اسم المستلم
                                </label>
                                <input
                                    type="text"
                                    name="name"
                                    value={recipient.name}
                                    onChange={(e) =>
                                        handleRecipientChange(index, e)
                                    }
                                    className="form-control"
                                    placeholder="أدخل اسم المستلم"
                                    required
                                />
                            </div>

                            <div className="col-lg-4 col-12">
                                <label className="form-label">
                                    البريد الإلكتروني للمستلم
                                </label>
                                <input
                                    type="email"
                                    name="email"
                                    value={recipient.email}
                                    onChange={(e) =>
                                        handleRecipientChange(index, e)
                                    }
                                    className="form-control"
                                    placeholder="أدخل البريد الإلكتروني"
                                    required
                                />
                            </div>

                            <div className="col-lg-3 col-12">
                                <label className="form-label">الجهة</label>
                                <select
                                    name="type"
                                    value={recipient.type}
                                    onChange={(e) =>
                                        handleRecipientChange(index, e)
                                    }
                                    className="form-select"
                                    required
                                >
                                    <option value="">اختر الجهة</option>
                                    <option value="منسوب للشركة">
                                        منسوب للشركة
                                    </option>
                                    <option value="خارجي">خارجي</option>
                                </select>
                            </div>
                            {formData.copyWithGreeting === 'yes' &&
                                formData.recipients.length > 1 && (
                                    <div className="col-lg-1 col-12 d-flex align-items-end mt-4">
                                        <button
                                            type="button"
                                            className="btn btn-danger"
                                            onClick={() =>
                                                removeRecipient(index)
                                            }
                                        >
                                            حذف
                                        </button>
                                    </div>
                                )}
                        </div>
                    ))}

                {/* Add Recipient Button */}
                {formData.copyWithGreeting === 'yes' && (
                    <div className="mb-3">
                        <button
                            type="button"
                            className="btn btn-secondary"
                            onClick={addRecipient}
                        >
                            إضافة مستلم آخر
                        </button>
                    </div>
                )}

                {/* HR Report Type */}
                {formData.department === 'إدارة الموارد البشرية' && (
                    <div className="mb-3 col-lg-6 col-12">
                        <label className="form-label">
                            نوع التقرير الخاص بالموارد البشرية
                        </label>
                        <select
                            name="hrReportType"
                            value={formData.hrReportType}
                            onChange={handleChange}
                            className="form-select"
                            required
                        >
                            <option value="">اختر النوع</option>
                            <option value="محضر إداري">محضر إداري</option>
                            <option value="يخص عامل">يخص عامل</option>
                        </select>
                    </div>
                )}

                {formData.hrReportType === 'يخص عامل' && (
                    <>
                        <div className="mb-3 col-lg-6 col-12">
                            <label className="form-label">اسم العامل</label>
                            <input
                                type="text"
                                name="employeeName"
                                value={formData.employeeName}
                                onChange={handleChange}
                                className="form-control"
                                placeholder="أدخل اسم العامل"
                                required
                            />
                        </div>

                        <div className="mb-3 col-lg-6 col-12">
                            <label className="form-label">
                                البريد الإلكتروني للعامل
                            </label>
                            <input
                                type="email"
                                name="employeeEmail"
                                value={formData.employeeEmail}
                                onChange={handleChange}
                                className="form-control"
                                placeholder="أدخل البريد الإلكتروني للعامل"
                                required
                            />
                        </div>
                    </>
                )}

                {/* Connection Type */}
                {formData.reportType === 'محضر اتصال' && (
                    <>
                        <div className="mb-3 col-lg-6 col-12">
                            <label className="form-label">نوع الاتصال</label>
                            <select
                                name="reportConnectionType"
                                value={formData.reportConnectionType}
                                onChange={handleChange}
                                className="form-select"
                                required
                            >
                                <option value="">اختر نوع الاتصال</option>
                                <option value="منسوب للشركة">
                                    منسوب للشركة
                                </option>
                                <option value="عميل">عميل</option>
                                <option value="خارجي">خارجي</option>
                            </select>
                        </div>

                        <div className="mb-3 col-lg-6 col-12">
                            <label className="form-label">اسم الشخص</label>
                            <input
                                type="text"
                                name="connectedPersonName"
                                value={formData.connectedPersonName}
                                onChange={handleChange}
                                className="form-control"
                                placeholder="أدخل اسم الشخص"
                                required
                            />
                        </div>

                        <div className="mb-3 col-lg-6 col-12">
                            <label className="form-label">
                                البريد الإلكتروني للشخص
                            </label>
                            <input
                                type="email"
                                name="connectedPersonEmail"
                                value={formData.connectedPersonEmail}
                                onChange={handleChange}
                                className="form-control"
                                placeholder="أدخل البريد الإلكتروني للشخص"
                                required
                            />
                        </div>

                        <div className="mb-3 col-lg-6 col-12">
                            <label className="form-label">
                                رقم الهاتف المتصل عليه
                            </label>
                            <input
                                type="text"
                                name="connectedPersonPhone"
                                value={formData.connectedPersonPhone}
                                onChange={handlePhoneChange}
                                className="form-control"
                                placeholder="أدخل رقم الهاتف"
                                required
                            />
                        </div>
                    </>
                )}

                {/* Report Nature */}
                <div className="mb-3 col-lg-6 col-12">
                    <label className="form-label">بيان نوعية المحضر</label>
                    <select
                        name="reportNature"
                        value={formData.reportNature}
                        onChange={handleChange}
                        className="form-select"
                        required
                    >
                        <option value="">اختر نوعية المحضر</option>
                        <option value="سري">سري</option>
                        <option value="عادي">عادي</option>
                    </select>
                </div>

                {/* Subject */}
                <div className="mb-3 col-12">
                    <label className="form-label">
                        موضوع المحضر (خانة إجبارية)
                    </label>
                    <textarea
                        name="subject"
                        value={formData.subject}
                        onChange={handleChange}
                        className="form-control"
                        rows="3"
                        placeholder="اكتب موضوع المحضر"
                        required
                    ></textarea>
                </div>

                {/* Report Details */}
                <div className="mb-3 col-12">
                    <label className="form-label">نص المحضر</label>
                    <textarea
                        name="reportDetails"
                        value={formData.reportDetails}
                        onChange={handleChange}
                        className="form-control"
                        rows="6"
                        style={{
                            fontSize: '16px',
                            color: 'blue',
                            lineHeight: '1.8',
                        }}
                        required
                    ></textarea>
                </div>

                {/* Attachments Section */}
                <div className="mb-3 col-lg-12 col-12">
                    <label className="form-label ms-2">المرفقات</label>
                    {attachments.map((file, index) => (
                        <div key={index} className="mb-3">
                            <input
                                type="file"
                                onChange={(e) => handleFileChange(e, index)}
                                className="form-control"
                                accept=".pdf,.doc,.docx,.jpg,.png"
                            />
                            <button
                                type="button"
                                className="btn btn-danger btn-sm mt-2"
                                onClick={() => removeAttachmentField(index)}
                            >
                                إزالة
                            </button>
                        </div>
                    ))}
                    {attachments.length < 5 && (
                        <button
                            type="button"
                            className="btn btn-secondary btn-sm"
                            onClick={addAttachmentField}
                        >
                            إضافة مرفق +
                        </button>
                    )}
                </div>

                <div className="text-center">
                    <button
                        type="submit"
                        className="btn btn-primary btn-lg"
                        disabled={isSubmitting}
                    >
                        {isSubmitting ? 'جاري الإرسال...' : 'إرسال'}
                    </button>
                </div>
            </form>
            <ToastContainer rtl position="top-right" autoClose={5000} />
        </div>
    );
};

export default CompanyReportFormComponent;
