import React from 'react';
import headerTopLeft from '../../images/header-left-top.png';
import headerBottomLeft from '../../images/header-left-bottom.png';
import headerTopRight from '../../images/header-right-top.png';
import headerBottomRight from '../../images/header-right-bottom.png';
import './Header.css';
import { useTranslation } from 'react-i18next';

const Header = () => {
    const { t } = useTranslation();
    return (
        <header
            className="masthead"
            style={{
                background: '#fff',
                paddingBottom: 'calc(10rem - 4.5rem)',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                backgroundAttachment: 'scroll',
                backgroundSize: 'cover',
            }}
        >
            <div
                className="container-fluid position-relative"
                style={{ height: 'calc(100%  - 143px)', zIndex: 1 }}
            >
                <div
                    className="row align-items-center justify-content-center text-center"
                    style={{ height: '100%' }}
                >
                    <div className="col-lg-8">
                        <h1
                            className="font-weight-bold brand"
                            style={{ fontWeight: '800' }}
                        >
                            {t('mshora company')}
                        </h1>
                    </div>
                    {/* <div className="col-lg-8 align-self-baseline">
                        <h3 className="brand-secondary mb-5">
                            {t('mshora company details')}
                        </h3>
                    </div> */}
                </div>
                <div className="header-left">
                    <img src={headerTopLeft} alt="" />
                    <img src={headerBottomLeft} alt="" />
                </div>
                <div className="header-right">
                    <img src={headerTopRight} alt="" />
                    <img src={headerBottomRight} alt="" />
                </div>
            </div>
        </header>
    );
};

export default Header;
