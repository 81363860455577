import { React, useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link/dist/react-router-hash-link.cjs.development';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faPhone,
    faEnvelope,
    faMapMarkerAlt,
} from '@fortawesome/free-solid-svg-icons';
import {
    faSnapchat,
    faXTwitter,
    faTiktok,
} from '@fortawesome/free-brands-svg-icons';
import './NavBar.css';
import logo from '../../images/logos/logo.png';
import unitedKingdom from '../../images/united-kingdom.webp';
import saudiArabia from '../../images/saudi-arabia.webp';
import { fetchUser } from '../../services/FetchUser';

const NavBar = () => {
    const [currentLanguage, setCurrentLanguage] = useState('ar');
    const location = useLocation();
    const { t, i18n } = useTranslation();

    const changeLanguageToArabic = () => {
        i18n.changeLanguage('ar');
        setCurrentLanguage(i18n.language);
    };
    const changeLanguageToEnglish = () => {
        i18n.changeLanguage('en');
        setCurrentLanguage(i18n.language);
    };


    useEffect(() => {
        document.body.dir = currentLanguage === 'en' ? 'ltr' : 'rtl';
        document.querySelector('html').lang = currentLanguage;
        document.querySelector('title').innerText =
            currentLanguage === 'en'
                ? 'Mshora Company - For legal consultancy, lawyers, documentation (notaries) and arbitration'
                : 'مشورة - للاستشارات الشرعية والقانونية والمحاماة والتوثيق والتحكيم';

        document.querySelector('meta[name="description"]').content =
            currentLanguage === 'en'
                ? 'Mshora Company - For legal consultancy, lawyers, documentation (notaries) and arbitration'
                : 'مشورة - للاستشارات الشرعية والقانونية والمحاماة والتوثيق والتحكيم';
    }, [currentLanguage]);

    useEffect(() => {
        const navbarCollapsible = document.body.querySelector('#mainNav');
        const contactNav = document.querySelector('.contact-nav');
        var hideSocials = function () {
            if (!navbarCollapsible) {
                return;
            }
            if (window.scrollY === 0 && location.pathname === '/') {
                contactNav.style.display = 'flex';
            } else {
                contactNav.style.display = 'none';
            }
        };

        hideSocials();

        document.addEventListener('scroll', hideSocials);

        const checkAuth = async () => {
            try {
                await fetchUser();
            } catch (err) {
            }
        };


        checkAuth();
    }, [location]);



    return (
        <>
            <nav
                className="navbar navbar-expand-lg navbar-light fixed-top py-3 d-flex justify-content-center align-items-center flex-column"
                id="mainNav"
            >
                <div className="container px-5 pb-3 contact-nav">
                    <div
                        style={{
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'space-between',
                        }}
                    >
                        <div className="nav-socials">
                            <Link to="https://x.com/mshora_com" target="_blank">
                                <FontAwesomeIcon
                                    className="me-4"
                                    size="lg"
                                    icon={faXTwitter}
                                />
                            </Link>
                            <Link
                                to="https://t.snapchat.com/MJ6QNCDo"
                                target="_blank"
                            >
                                <FontAwesomeIcon
                                    className="me-4"
                                    size="lg"
                                    icon={faSnapchat}
                                />
                            </Link>
                            <Link
                                to="https://www.tiktok.com/@mshora_com"
                                target="_blank"
                            >
                                <FontAwesomeIcon
                                    className="me-4"
                                    size="lg"
                                    icon={faTiktok}
                                />
                            </Link>
                        </div>
                        <div className="nav-info" dir="ltr">
                            <Link
                                className="me-3"
                                to="https://maps.app.goo.gl/PqbASR5frwFG6is49?g_st=iwb"
                                target="_blank"
                            >
                                <FontAwesomeIcon
                                    className="me-2"
                                    size="lg"
                                    icon={faMapMarkerAlt}
                                />
                                {t('riyadh headquarter')}
                            </Link>
                            <Link className="me-3" to="mailto:info@mshora.com">
                                <FontAwesomeIcon
                                    className="me-2"
                                    size="lg"
                                    icon={faEnvelope}
                                />
                                info@mshora.com
                            </Link>
                            <Link className="me-3" to="tel:00966114532502">
                                <FontAwesomeIcon
                                    className="me-2"
                                    size="lg"
                                    icon={faPhone}
                                />{' '}
                                +966 9200 06294
                            </Link>
                        </div>
                    </div>
                </div>

                <div className="container px-4 px-lg-5">
                    <HashLink className="navbar-brand mshora-logo" to="/#">
                        <img
                            src={logo}
                            width="150"
                            alt="مشورة للاستشارات الشرعية"
                        />
                    </HashLink>
                    <button
                        className="navbar-toggler navbar-toggler-right"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#navbarResponsive"
                        aria-controls="navbarResponsive"
                        aria-expanded="false"
                        aria-label="Toggle navigation"
                    >
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div
                        className="collapse navbar-collapse"
                        id="navbarResponsive"
                    >
                        <ul
                            className={`navbar-nav ${
                                i18n.language === 'en' ? 'ms-auto' : 'me-auto'
                            } my-2 my-lg-0`}
                        >
                            <li className="nav-item">
                                <HashLink className="nav-link" to="/#about">
                                    {t('about us')}
                                </HashLink>
                            </li>
                            <li className="nav-item">
                                <HashLink className="nav-link" to="/#services">
                                    {t('our services')}
                                </HashLink>
                            </li>
                            {/* <li className="nav-item">
                                <Link className="nav-link" to="/eid-card">
                                    {t('eid card')}
                                </Link>
                            </li> */}
                            <li className="nav-item">
                                <Link className="nav-link" to="/contact">
                                    {t('contact us')}
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link
                                    className="btn btn-primary"
                                    target="_blank"
                                    to="https://portal.mshora.com"
                                    style={{
                                        borderRadius: '2rem',
                                        padding: '8px 16px',
                                    }}
                                >
                                    {t('login')}
                                </Link>
                            </li>
                            <li
                                className="nav-item mx-3 mt-2"
                                style={{
                                    textAlign: 'end',
                                }}
                            >
                                {i18n.language === 'en' ? (
                                    <img
                                        src={saudiArabia}
                                        onClick={changeLanguageToArabic}
                                        style={{ cursor: 'pointer' }}
                                        alt="العربية"
                                    />
                                ) : (
                                    <img
                                        src={unitedKingdom}
                                        onClick={changeLanguageToEnglish}
                                        style={{ cursor: 'pointer' }}
                                        alt="English"
                                    />
                                )}
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        </>
    );
};

export default NavBar;
