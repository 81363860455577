import React, { useEffect, useState } from 'react';
import './Contact.css';
import emailjs from '@emailjs/browser';
import { useNavigate } from 'react-router-dom';
import contactLeftTop from '../../images/header-left-top.png';
import contactLeftBottom from '../../images/header-left-bottom.png';
import contactRightTop from '../../images/header-right-top.png';
import contactRightBottom from '../../images/header-right-bottom.png';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import i18n from '../../i18n';

const Contact = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        message: '',
    });

    const [formErrors, setFormErrors] = useState({
        name: '',
        email: '',
        phone: '',
        message: '',
    });

    const [validFormData, setValidFormData] = useState(false);
    const [submitClicked, setSubmitClicked] = useState(false);
    const [height, setHeight] = useState('40vw');
    const [width, setWidth] = useState('40vw');
    const [successMessage, setSuccessMessage] = useState('');
    const [icon, setIcon] = useState('');

    const navigate = useNavigate();

    const { t } = useTranslation();

    const sendEmail = async () => {
        try {
            await emailjs.sendForm(
                process.env.REACT_APP_EMAILJS_SERVICE_ID,
                process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
                '#contactForm',
                process.env.REACT_APP_EMAILJS_PUBLIC_KEY
            );
        } catch (err) {
            navigate('/404');
        }
    };

    const shrinkAfterSubmit = () => {
        setTimeout(() => {
            setHeight('10vw');
            setWidth('10vw');
            setSuccessMessage(t('sent successfully'));
            setTimeout(() => {
                setIcon(<FontAwesomeIcon icon={faCheck} size="4x" />);
            }, 100);
        }, 500);
    };

    function isValidEmail(email) {
        return /\S+@\S+\.\S+/.test(email);
    }

    const validateForm = (e) => {
        const inputName = e.target.name;
        const inputValue = e.target.value;

        if (inputName === 'phone' && isNaN(inputValue) && inputValue !== '+') {
            return;
        }
        setFormData((prevData) => ({ ...prevData, [inputName]: inputValue }));

        if (inputName === 'name') {
            if (inputValue.length === 0) {
                setFormErrors((prevErrors) => ({
                    ...prevErrors,
                    [inputName]: 'required',
                }));
            } else {
                setFormErrors((prevErrors) => ({
                    ...prevErrors,
                    [inputName]: '',
                }));
            }
        } else if (inputName === 'email') {
            if (!isValidEmail(inputValue)) {
                setFormErrors((prevErrors) => ({
                    ...prevErrors,
                    [inputName]: 'invalid',
                }));
            } else {
                setFormErrors((prevErrors) => ({
                    ...prevErrors,
                    [inputName]: '',
                }));
            }
        } else if (inputName === 'phone') {
            if (
                inputValue.length !== 15 &&
                inputValue.length !== 14 &&
                inputValue.length !== 13 &&
                inputValue.length !== 11 &&
                inputValue.length !== 10
            ) {
                setFormErrors((prevErrors) => ({
                    ...prevErrors,
                    [inputName]: 'invalid',
                }));
            } else {
                setFormErrors((prevErrors) => ({
                    ...prevErrors,
                    [inputName]: '',
                }));
            }
        } else if (inputName === 'message') {
            if (inputValue.length === 0) {
                setFormErrors((prevErrors) => ({
                    ...prevErrors,
                    [inputName]: 'required',
                }));
            } else {
                setFormErrors((prevErrors) => ({
                    ...prevErrors,
                    [inputName]: '',
                }));
            }
        }
    };

    const handleChange = (e) => {
        validateForm(e);
    };

    const handleBlur = (e) => {
        validateForm(e);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (formData.name.length === 0) {
            setFormErrors((prevErrors) => ({
                ...prevErrors,
                name: 'required',
            }));
        } else {
            setFormErrors((prevErrors) => ({
                ...prevErrors,
                name: '',
            }));
        }

        if (!isValidEmail(formData.email)) {
            setFormErrors((prevErrors) => ({
                ...prevErrors,
                email: 'invalid',
            }));
        } else {
            setFormErrors((prevErrors) => ({
                ...prevErrors,
                email: '',
            }));
        }
        if (
            formData.phone.length !== 15 &&
            formData.phone.length !== 14 &&
            formData.phone.length !== 13 &&
            formData.phone.length !== 11 &&
            formData.phone.length !== 10
        ) {
            setFormErrors((prevErrors) => ({
                ...prevErrors,
                phone: 'invalid',
            }));
        } else {
            setFormErrors((prevErrors) => ({
                ...prevErrors,
                phone: '',
            }));
        }
        if (formData.message.length === 0) {
            setFormErrors((prevErrors) => ({
                ...prevErrors,
                message: 'required',
            }));
        } else {
            setFormErrors((prevErrors) => ({
                ...prevErrors,
                message: '',
            }));
        }

        if (validFormData) {
            setSubmitClicked(true);
            sendEmail();
            shrinkAfterSubmit();
        }
    };
    useEffect(() => {
        if (
            formErrors.name ||
            formErrors.email ||
            formErrors.phone ||
            formErrors.message ||
            !formData.name ||
            !formData.email ||
            !formData.phone ||
            !formData.message
        ) {
            setValidFormData(false);
            setSubmitClicked(false);
        } else {
            setValidFormData(true);
        }
    }, [formErrors, formData]);

    return (
        <section
            id="contact"
        >
            <div
                className={`container px-4 px-xl-5 pb-5 text-center ${
                    i18n.language === 'en' ? 'text-lg-start' : 'text-lg-end'
                }`}
            >
                <div className="row">
                    <div className="col-12">
                        <h1>{t('request consultation now')}</h1>
                    </div>
                </div>
            </div>
            <div
                className="container px-4 px-xl-5 bg-brand-secondary"
                style={{ borderRadius: '24px' }}
            >
                <div
                    className={`row p-2 p-xl-5 position-relative ${
                        submitClicked &&
                        validFormData &&
                        'd-flex flex-column justify-content-center align-items-center'
                    }`}
                    style={{
                        backgroundPosition: 'right',
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: 'cover',
                        borderRadius: '1rem',
                        minHeight: '100vh',
                        zIndex: 1,
                    }}
                >
                    <div
                        className={`col-12 col-xl-7 p-2 p-xl-5 ${
                            submitClicked &&
                            validFormData &&
                            'submitted d-flex flex-column justify-content-center align-items-center'
                        }`}
                        style={{
                            transition: 'all 0.2s ease-out',
                            borderRadius:
                                submitClicked && validFormData ? '50%' : '1rem',
                            height: submitClicked && validFormData && height,
                            width: submitClicked && validFormData && width,
                            minWidth: '128px',
                            minHeight: '128px',
                        }}
                    >
                        {submitClicked && validFormData && <div>{icon}</div>}
                        <div
                            className="row justify-content-center"
                            style={{
                                display:
                                    submitClicked && validFormData
                                        ? 'none'
                                        : 'flex',
                            }}
                        >
                            <div
                                className={`col-12 text-center ${
                                    i18n.language === 'en'
                                        ? 'text-lg-start'
                                        : 'text-lg-end'
                                }`}
                            >
                                <h2 className="mt-0">
                                    {t('you can request consultation remotely')}
                                </h2>
                                <p className="text-muted mb-5">
                                    {t('we will get back to you asap')}
                                </p>
                            </div>
                        </div>
                        <div
                            className="row justify-content-center mb-5"
                            style={{
                                display:
                                    submitClicked && validFormData
                                        ? 'none'
                                        : 'flex',
                            }}
                        >
                            <div className="col-12">
                                <form id="contactForm" onSubmit={handleSubmit}>
                                    <div className="form-floating mb-3">
                                        <input
                                            className="form-control"
                                            id="name"
                                            name="name"
                                            type="text"
                                            placeholder={t('name')}
                                            data-validations="required"
                                            value={formData.name}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                        />
                                        <label htmlFor="name">
                                            {t('name')}
                                        </label>
                                        {formErrors.name === 'required' ? (
                                            <div
                                                className="invalid-feedback"
                                                style={{ display: 'block' }}
                                            >
                                                {t('name is required')}
                                            </div>
                                        ) : (
                                            ''
                                        )}
                                    </div>
                                    <div className="form-floating mb-3">
                                        <input
                                            className="form-control"
                                            id="email"
                                            name="email"
                                            type="text"
                                            placeholder={t('email')}
                                            value={formData.email}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                        />
                                        <label htmlFor="email">
                                            {t('email')}
                                        </label>
                                        {formErrors.email === 'invalid' ? (
                                            <div
                                                className="invalid-feedback"
                                                style={{ display: 'block' }}
                                            >
                                                {t('invalid email')}
                                            </div>
                                        ) : (
                                            ''
                                        )}
                                    </div>
                                    <div className="form-floating mb-3">
                                        <input
                                            className="form-control"
                                            id="phone"
                                            name="phone"
                                            type="tel"
                                            placeholder={t('phone')}
                                            value={formData.phone}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            style={{
                                                direction: 'ltr',
                                                textAlign:
                                                    i18n.language === 'en'
                                                        ? 'left'
                                                        : 'right',
                                            }}
                                        />
                                        <label htmlFor="phone">
                                            {t('phone')}
                                        </label>
                                        {formErrors.phone === 'invalid' ? (
                                            <div
                                                className="invalid-feedback"
                                                style={{ display: 'block' }}
                                            >
                                                {t('invalid phone number')}
                                            </div>
                                        ) : (
                                            ''
                                        )}
                                    </div>
                                    <div className="form-floating mb-3">
                                        <textarea
                                            className="form-control"
                                            id="message"
                                            name="message"
                                            type="text"
                                            placeholder={t('message')}
                                            style={{ height: '10rem' }}
                                            value={formData.message}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                        ></textarea>
                                        <label htmlFor="message">
                                            {t('message')}
                                        </label>

                                        {formErrors.message === 'required' ? (
                                            <div
                                                className="invalid-feedback"
                                                style={{ display: 'block' }}
                                            >
                                                {t('message is required')}
                                            </div>
                                        ) : (
                                            ''
                                        )}
                                    </div>
                                    <div
                                        className="d-none"
                                        id="submitSuccessMessage"
                                    ></div>
                                    <div
                                        className={`text-center ${
                                            i18n.language === 'en'
                                                ? 'text-lg-start'
                                                : 'text-lg-end'
                                        }`}
                                    >
                                        <button
                                            className={`btn btn-primary btn-xl mt-3 ${
                                                submitClicked && validFormData
                                                    ? 'disabled'
                                                    : ''
                                            }`}
                                            id="submitButton"
                                            type="submit"
                                        >
                                            {t('get consultation')}
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>

                        <div></div>
                    </div>
                    <div
                        className="text-center"
                        style={{
                            display:
                                submitClicked && validFormData
                                    ? 'block'
                                    : 'none',
                        }}
                    >
                        {submitClicked && validFormData && (
                            <h3
                                style={{
                                    marginTop: '1rem',
                                    textShadow: '2px 2px 4px rgba(0,0,0,0.5)',
                                }}
                            >
                                {successMessage}
                            </h3>
                        )}
                    </div>
                    <div
                        className={`p-xl-7 ${
                            i18n.language === 'ar'
                                ? 'contact-left'
                                : 'contact-right'
                        }`}
                    >
                        <img
                            src={
                                i18n.language === 'ar'
                                    ? contactLeftTop
                                    : contactRightTop
                            }
                            alt=""
                        />
                        <img
                            src={
                                i18n.language === 'ar'
                                    ? contactLeftBottom
                                    : contactRightBottom
                            }
                            alt=""
                        />
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Contact;
